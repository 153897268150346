import React from 'react';
import {Col, Row, Separator} from '../../../utils';
import {Paragraph} from '../../../fields';
import {ButtonFeedback, Card, CardBody, CardHeader} from './styles';
import {VacancyPoint} from '../store/types';

const ItemRow = ({firstText, secondText}: { firstText: string, secondText: string }) => {
    return (
        <Row>
            <Col span={6}>
                <Paragraph as={'span'} fontSize={14} colorType={'second'} weight={'400'}>{firstText}</Paragraph>
            </Col>
            <Col span={6} direction={'flex-end'}>
                <Paragraph as={'span'} fontSize={16} colorType={'main'} weight={'400'}>{secondText}</Paragraph>
            </Col>
        </Row>
    );
};

export const VacancyCard = ({item}: { item: VacancyPoint }) => {
    return (
        <Card>
            <CardHeader>
                <Paragraph as={'span'} fontSize={14} colorType={'main'} weight={'400'}>вакансия:</Paragraph>
                <Paragraph as={'span'} fontSize={16} colorType={'main'} weight={'400'}
                           style={{textTransform: 'uppercase'}}>{item.StaffName}</Paragraph>
            </CardHeader>
            <CardBody>
                <ItemRow firstText={item.ObjPlaceType} secondText={item.ObjName}/>
                <Row>
                    <Col span={3}>
                        <Paragraph as={'p'} fontSize={14} colorType={'second'} weight={'400'}>Позиций:</Paragraph>
                    </Col>
                    <Col span={2}>
                        <Paragraph as={'p'} fontSize={14} colorType={'main'} weight={'400'}>{item.Cnt}</Paragraph>
                    </Col>
                </Row>
                <Separator space={10}/>
                <Paragraph as={'p'} fontSize={14} colorType={'main'} weight={'400'}>Рабочее время:</Paragraph>
                <Row>
                    <Col span={6}>
                        <ItemRow firstText={'Начало:'} secondText={item.WorkDayStartTime ?? '--'}/>
                    </Col>
                    <Col span={6}>
                        <ItemRow firstText={'Конец:'} secondText={item.WorkDayEndTime ?? '--'}/>
                    </Col>
                </Row>
                <Paragraph as={'p'} fontSize={14} colorType={'main'} weight={'400'}>Стоимость</Paragraph>
                <Row>
                    <Col span={6}>
                        <ItemRow firstText={'день'} secondText={item.DailyRate ?? '--'}/>
                    </Col>
                    <Col span={6}>
                        <ItemRow firstText={'час'} secondText={item.HourlyRate ?? '--'}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <ItemRow firstText={'километр'} secondText={item.KilometerRate ?? '--'}/>
                    </Col>
                    <Col span={6}>
                        <ItemRow firstText={'доставка'} secondText={item.DeliveryRate ?? '--'}/>
                    </Col>
                </Row>
                <ButtonFeedback full={false}>Откликнуться</ButtonFeedback>
            </CardBody>
        </Card>
    );
};
