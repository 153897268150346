import React from 'react';
import {Col, Row} from '../../utils';
import {Paragraph, Picker, SelectCustom} from '../../fields';
import {useGetObjectsQuery} from '../../store/api';
import {useAppDispatch, useAppSelector} from '../../hooks/hook-store';
import {setWorkShiftFilter} from '../../screens/shiftOrders/store/shift.orders.slice';


export const ShiftFilter = () => {
    const dispatch = useAppDispatch();
    const {data: objects} = useGetObjectsQuery();
    const {filter} = useAppSelector(state => state.shiftOrders);

    const onChangeFilter = (dateStrings: string[]) => {
        const newFilter = filter.map(key => {
            if (key.type === 'Start') return {...key, value: dateStrings[0]};
            if (key.type === 'Finish') return {...key, value: dateStrings[1]};
            return key;
        });

        dispatch(setWorkShiftFilter(newFilter));
    };

    const handleSetFilter = (value: string) => {
        if (filter.some(key => key.type === 'FObjects')) {
            const newKeys = filter.map(item => {
                if (item.type === 'FObjects') {
                    const currentValues = Array.isArray(item.value) ? item.value : [item.value];

                    if (currentValues.includes(value)) {
                        const updatedValues = currentValues.filter(v => v !== value);

                        return updatedValues.length === 0 ? null : {...item, value: updatedValues};
                    } else {
                        return {...item, value: [...currentValues, value]};
                    }
                }
                return item;
            }).filter(item => item !== null);
            dispatch(setWorkShiftFilter(newKeys));
        } else {
            dispatch(setWorkShiftFilter([...filter, {type: 'FObjects', value: [value]}]));
        }
    };


    return (
        <>
            <Row>
                <Col span={2}>
                    <Paragraph as={'span'} fontSize={14} weight={'400'} colorType={'main'}>Период</Paragraph>
                </Col>
                <Col span={10}>
                    <Picker
                        height={40}
                        onChange={(dateStrings) =>
                            onChangeFilter(dateStrings)}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <Paragraph as={'span'} fontSize={14} weight={'400'} colorType={'main'}>Обьект</Paragraph>
                </Col>
                <Col span={10}>
                    <SelectCustom
                        mode={'multiple'}
                        options={objects}
                        height={40}
                        disabled={false}
                        handleSelect={(value) => handleSetFilter(value)}/>
                </Col>
            </Row>
        </>
    );
};
