import React, {useEffect, useState} from 'react';
import {AuthContainer, Checkmark, FormLogin, InputAuth} from './styles';
import {Icon, IconWrap} from '../../assets/icon';
import {Box, Separator, themes} from '../../utils';
import {Button, Checkbox, Paragraph, Switcher} from '../../fields';
import {InputMask} from '@react-input/mask';
import {AuthNavigate} from './components';
import {Option} from '../../store/types';
import {Tooltip} from '../../components/tooltip';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {useLoginMutation, useRegistrationMutation} from './store/auth.api';
import {registerFaceId} from '../../authFunc';
import CryptoJS from 'crypto-js';
import {useAppDispatch, useAppSelector} from '../../hooks/hook-store';
import {setRegForm} from './store/auth.slice';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import {RegistrationForm} from './store/types';
import {setBarcode} from '../scanner/store/scanner.slice';


const schema = yup
    .object()
    .shape({
        name: yup.string().required(),
        phone: yup.string().required(),
        pwd: yup.string().required(),
        qrContent: yup.string().required(),
        confirmPassword: yup.string().required(),
        faceId: yup.string()
    })
    .required();

const switcherOptions: Option[] = [
    {label: 'Вне штата', value: 'Вне штата'},
    {label: 'Сотрудник', value: 'Сотрудник'}
];


export const Registration = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [showPass, setShowPass] = useState<boolean>(true);
    const [showPassConfirm, setShowPassConfirm] = useState<boolean>(true);
    const [isApprove, setApprove] = useState<boolean>(false);

    const [selected, setSelected] = useState<Option>(switcherOptions[0]);
    const [activeTooltipId, setActiveTooltipId] = useState<string | null>(null);

    const {form} = useAppSelector(state => state.auth);
    const {barcode} = useAppSelector(state => state.scanner);
    const [registration] = useRegistrationMutation();
    const [saveLogin] = useLoginMutation();
    const {register, watch, setValue, clearErrors, formState: {errors}, handleSubmit} = useForm<RegistrationForm>({
        resolver: yupResolver(schema),
        defaultValues: form
    });


    useEffect(() => {
        if (barcode) {
            changeForm(barcode, 'qrContent');
            if (errors.qrContent) {
                clearErrors('qrContent');
            }
            for (const key in form) {
                setValue(key as keyof RegistrationForm, form[key as keyof RegistrationForm]);
            }
            setValue('qrContent', barcode);
            dispatch(setBarcode(null));
        }

    }, []);

    const goToScanner = () => {
        navigate(`/scanner`, {state: {pathFrom: '/auth/registration'}});
    };

    const getFaceId = async () => {
        const {credentialId} = await registerFaceId({name: 'ifbx-courier', displayName: 'ifbx-courier'});
        if (credentialId) {
            setValue('faceId', credentialId);
            dispatch(setRegForm({...form, faceId: credentialId}));
        } else {
            toast.error('Распознавание телефоном необходимо для корректной работы приложения!', {
                autoClose: false
            });
        }
    };

    const onSubmit = async () => {
        const md5Hash = CryptoJS.MD5(form.pwd).toString();
        const body = {...form, pwd: md5Hash, phone: form.phone.replace(/\D/g, '')};

        registration(body)
            .unwrap()
            .then(res => {
                if (res.status) {
                    const login = res.userInfo.Phone;
                    saveLogin({login, pwd: md5Hash}).unwrap().then(res => {
                        if (res.status) {
                            navigate('/');
                        }
                    });
                }
            })
            .catch(error => {
                toast.error((error.data as any)?.message ?? 'Ошибка');
            });
    };

    const handleSelectRole = (opt: Option) => {
        if (opt.value === 'Сотрудник') {
            toast.info('Обратитесь к директору объекта', {
                autoClose: false,
                onClose: () => setSelected(switcherOptions[0])
            });
        } else {
            setSelected(opt);
        }
    };

    const changeForm = (value: string, field: string) => {
        setValue(field as keyof RegistrationForm, value);
        dispatch(setRegForm({...form, [field]: value}));
    };

    return (
        <AuthContainer flexGrow={1} flexDirection={'column'}>
            <FormLogin
                onSubmit={handleSubmit(onSubmit)}
                paddingTop={0}>
                <Paragraph
                    as={'p'}
                    fontSize={24}
                    textPosition={'center'}
                    colorType={'main'}
                    weight={'500'}>Регистрация</Paragraph>
                <Box padding={'10px 10px 15px'}>
                    {/*<Paragraph*/}
                    {/*    as={'span'}*/}
                    {/*    fontSize={14}*/}
                    {/*    colorType={'main'}*/}
                    {/*    textPosition={'left'}*/}
                    {/*    weight={'400'}>Фамилия Имя Отчество</Paragraph>*/}
                    <Box flex={true} flexDirection={'row'} padding={'0px'}>
                        <InputAuth as={'div'} height={50}>
                            <IconWrap width={45} height={45}>
                                <Icon type={'user'} color={themes.colors.borderColor}/>
                            </IconWrap>
                            <input
                                {...register('name')}
                                onFocus={() => clearErrors('name')}
                                onChange={event => changeForm(event.target.value, 'name')}
                                placeholder={'Фамилия Имя Отчество'}
                                autoCapitalize={'words'}
                                type={'text'}
                            />
                        </InputAuth>
                        <Separator space={10}/>
                        <Tooltip
                            activeTooltipId={activeTooltipId}
                            setActiveTooltipId={setActiveTooltipId}
                            text={'Необходимо убедиться что вы находитесь на месте.'}
                        />
                    </Box>
                </Box>
                <Box padding={'10px 10px 15px'}>
                    {/*<Paragraph*/}
                    {/*    as={'span'}*/}
                    {/*    fontSize={14}*/}
                    {/*    colorType={'main'}*/}
                    {/*    textPosition={'left'}*/}
                    {/*    weight={'400'}>Tелефон</Paragraph>*/}
                    <Box flex={true} flexDirection={'row'} padding={'0px'}>
                        <InputAuth as={'div'} height={50}>
                            <IconWrap width={45} height={45}>
                                <Icon type={'phone'} color={themes.colors.borderColor}/>
                            </IconWrap>
                            <InputMask
                                {...register('phone')}
                                onFocus={() => clearErrors('phone')}
                                onChange={event => changeForm(event.target.value, 'phone')}
                                placeholder={'7 (___) ___-__-__'}
                                mask="7 (___) ___-__-__"
                                replacement={{_: /\d/}}
                            />
                        </InputAuth>
                        <Separator space={10}/>
                        <Tooltip
                            activeTooltipId={activeTooltipId}
                            setActiveTooltipId={setActiveTooltipId}
                            text={'Необходимо убедиться что вы находитесь на месте.'}
                        />
                    </Box>
                </Box>
                <Box padding={'10px 10px 15px'}>
                    <Box flex={true} flexDirection={'row'} padding={'0px'}>
                        <Switcher
                            height={50}
                            options={switcherOptions}
                            selected={selected}
                            onSwitch={(opt) => handleSelectRole(opt)}/>
                        <Separator space={10}/>
                        <Tooltip
                            activeTooltipId={activeTooltipId}
                            setActiveTooltipId={setActiveTooltipId}
                            text={'Необходимо убедиться что вы находитесь на месте.'}
                        />
                    </Box>
                </Box>
                <Box flex={true} flexDirection={'row'} padding={'10px 10px 15px'}>
                    <Button
                        full={true}
                        fontSize={18}
                        type={'button'}
                        height={50}
                        onClick={goToScanner}
                    >
                        Отсканируйте QR код
                        {form?.qrContent !== '' ? <Checkmark/> : null}
                    </Button>
                    <Separator space={10}/>
                    <Tooltip
                        activeTooltipId={activeTooltipId}
                        setActiveTooltipId={setActiveTooltipId}
                        text={'Необходимо убедиться что вы находитесь на месте.'}
                    />
                </Box>
                <Box flex={true} flexDirection={'row'} padding={'10px 10px 15px'}>
                    <Button
                        full={true}
                        fontSize={18}
                        type={'button'}
                        height={50}
                        onClick={getFaceId}>
                        Распознавание телефоном
                        {form?.faceId !== '' ? <Checkmark/> : null}
                    </Button>
                    <Separator space={10}/>
                    <Tooltip
                        activeTooltipId={activeTooltipId}
                        setActiveTooltipId={setActiveTooltipId}
                        text={'Необходимо для работы приложения.'}
                    />
                </Box>

                <Box padding={'10px 10px 15px'}>
                    {/*<Paragraph*/}
                    {/*    as={'span'}*/}
                    {/*    fontSize={14}*/}
                    {/*    colorType={'main'}*/}
                    {/*    textPosition={'left'}*/}
                    {/*    weight={'400'}>Пароль</Paragraph>*/}
                    <Box flex={true} flexDirection={'row'} padding={'0px'}>
                        <InputAuth as={'div'} height={50}>
                            <IconWrap width={45} height={45} onClick={() => setShowPass(!showPass)}>
                                <Icon type={'lock'} color={themes.colors.borderColor}/>
                            </IconWrap>
                            <input
                                {...register('pwd')}
                                onFocus={() => clearErrors('pwd')}
                                onChange={event => changeForm(event.target.value, 'pwd')}
                                placeholder={'Пароль'}
                                autoCapitalize={'words'}
                                autoComplete={'off'}
                                type={showPass ? 'password' : 'text'}
                            />
                        </InputAuth>
                        <Separator space={10}/>
                        <Tooltip
                            activeTooltipId={activeTooltipId}
                            setActiveTooltipId={setActiveTooltipId}
                            text={'Необходимо для работы приложения.'}
                        />
                    </Box>
                </Box>
                <Box padding={'10px 10px 15px'}>
                    {/*<Paragraph*/}
                    {/*    as={'span'}*/}
                    {/*    fontSize={14}*/}
                    {/*    colorType={'main'}*/}
                    {/*    textPosition={'left'}*/}
                    {/*    weight={'400'}>Подтвердите пароль</Paragraph>*/}
                    <Box flex={true} flexDirection={'row'} padding={'0px'}>
                        <InputAuth as={'div'} height={50}>
                            <IconWrap width={45} height={45} onClick={() => setShowPassConfirm(!showPassConfirm)}>
                                <Icon type={'lock'} color={themes.colors.borderColor}/>
                            </IconWrap>
                            <input
                                {...register('confirmPassword', {
                                    required: 'Подтвердите пароль',
                                    validate: value =>
                                        value === watch('pwd') || 'Пароли не совпадают'
                                })}
                                autoCapitalize={'words'}
                                autoComplete={'off'}
                                onFocus={() => clearErrors('confirmPassword')}
                                onChange={event => changeForm(event.target.value, 'confirmPassword')}
                                placeholder={'Подтвердите пароль'}
                                type={showPassConfirm ? 'password' : 'text'}
                            />
                        </InputAuth>
                        <Separator space={10}/>
                        <Tooltip
                            activeTooltipId={activeTooltipId}
                            setActiveTooltipId={setActiveTooltipId}
                            text={'Необходимо для работы приложения.'}
                        />
                    </Box>
                </Box>
                <Box>
                    <Checkbox
                        status={isApprove}
                        textDirection={'right'}
                        onChange={setApprove}
                        text={'согласен с политикой обработки и хранения персональных и биометрических данных'}/>
                </Box>
                <Box>
                    <Button
                        type={'submit'}
                        color={'main'}
                        full={true}
                        height={50}
                        disabled={!isApprove}
                        fontSize={16}
                    >
                        Вход
                    </Button>
                </Box>
            </FormLogin>
            <Separator space={10}/>
            <AuthNavigate
                href={'/auth/login'}
                subtext={'Есть логин?'}
                text={'Авторизуйся...'}
            />
        </AuthContainer>
    );
};
