import React, {useState} from 'react';
import {Icon, IconWrap, LogoIcon} from '../../assets/icon';
import {Box, Col, Row, Separator, themes} from '../../utils';
import {AuthContainer, FormLogin, InputAuth} from './styles';
import {Button, Checkbox, Paragraph} from '../../fields';
import {useForm} from 'react-hook-form';
import {LoginForm} from './store/types';
import {AuthNavigate} from './components';
import CryptoJS from 'crypto-js';
import {useGetKeyMutation, useLoginMutation} from './store/auth.api';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {authenticateFaceId} from '../../authFunc';
import {InputMask} from '@react-input/mask';
import {useAppDispatch} from '../../hooks/hook-store';
import {byFaceId} from './store/auth.slice';


export const Auth = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState<boolean>(true);
    const [isApprove, setApprove] = useState<boolean>(false);
    const [saveLogin, {isLoading}] = useLoginMutation();
    const [getKey] = useGetKeyMutation();
    const {register, handleSubmit} = useForm<LoginForm>();

    const onSubmit = (data: LoginForm) => {
        const md5Hash = CryptoJS.MD5(data.pwd).toString();
        const login = data.login.replace(/\D/g, '');
        //@todo надо проверять пароль
        saveLogin({login, pwd: md5Hash})
            .unwrap()
            .then(res => {
                if (res.status) {
                    navigate('/');
                }
            })
            .catch(error => {
                toast.error((error.data as any)?.message ?? 'Ошибка');
            });
    };

    const loginByFaceId = () => {
        getKey()
            .unwrap()
            .then(async ({FaceID, FingerPrint}) => {
                if (FaceID) {
                    const {credentialId} = await authenticateFaceId({base64encodedCredentialId: FaceID});
                    if (credentialId) {
                        dispatch(byFaceId());
                    }
                }
            })
            .catch(err => {
                toast.error(JSON.stringify(err, null, 2), {
                    autoClose: false
                });
            });
    };

    return (
        <AuthContainer flexGrow={1} flexDirection={'column'}>
            <Paragraph
                as={'p'}
                fontSize={24}
                colorType={'current-theme'}
                textPosition={'center'}
                weight={'500'}>КУРЬЕРСКОЕ ПРИЛОЖЕНИЕ</Paragraph>
            <Separator space={30}/>
            <LogoIcon color={themes.colors.main}/>
            <FormLogin paddingTop={30} onSubmit={handleSubmit(onSubmit)}>
                <Paragraph
                    as={'p'}
                    fontSize={24}
                    textPosition={'center'}
                    weight={'500'}>{isLoading ? 'Загрузка...' : 'Авторизация'}</Paragraph>
                <Box padding={'10px 10px 20px'}>
                    <Paragraph
                        as={'span'}
                        fontSize={14}
                        colorType={'main'}
                        textPosition={'left'}
                        weight={'400'}>Телефон</Paragraph>
                    <InputAuth as={'div'} height={50}>
                        <IconWrap width={45} height={45}>
                            <Icon type={'phone'} color={themes.colors.borderColor}/>
                        </IconWrap>
                        <InputMask
                            {...register('login')}
                            placeholder={'_ (___) ___-__-__'}
                            mask="_ (___) ___-__-__"
                            replacement={{_: /\d/}}
                        />
                    </InputAuth>
                </Box>
                <Box padding={'10px 10px 20px'}>
                    <Paragraph
                        as={'span'}
                        fontSize={14}
                        colorType={'main'}
                        textPosition={'left'}
                        weight={'400'}>Пароль</Paragraph>
                    <InputAuth as={'div'} height={50}>
                        <IconWrap width={45} height={45} onClick={() => setShowPass(!showPass)}>
                            <Icon type={'lock'} color={themes.colors.borderColor}/>
                        </IconWrap>
                        <input
                            {...register('pwd')}
                            placeholder={''}
                            type={showPass ? 'password' : 'text'}
                        />
                    </InputAuth>
                </Box>
                <Box>
                    <Checkbox
                        status={isApprove}
                        textDirection={'right'}
                        onChange={setApprove}
                        text={'согласен с политикой обработки и хранения персональных и биометрических данных'}/>
                </Box>
                <Box>
                    <Row>
                        <Col span={4} onClick={loginByFaceId}>
                            <Icon type={'faceId'} color={themes.colors.white} width={60} height={60}/>
                        </Col>
                        <Col span={8}>
                            <Button
                                type={'submit'}
                                color={'main'}
                                full={true}
                                height={50}
                                disabled={!isApprove}
                                fontSize={16}
                            >
                                Вход
                            </Button>
                        </Col>
                    </Row>

                </Box>
            </FormLogin>
            <Separator space={10}/>
            <AuthNavigate
                href={'/auth/registration'}
                subtext={'Нет логина?'}
                text={'Регистрируйся...'}
            />
        </AuthContainer>
    );
};
