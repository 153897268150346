import {api} from '../../../store/api';
import {Staff, Vacancy} from './types';
import {Option} from '../../../store/types';

const vacanciesApi = api.injectEndpoints({
    endpoints: build => ({
        getVacancies: build.query<Vacancy[], { params: string }>({
            query: ({params}) => ({
                url: `/vacancies/map?${params}`,
                method: 'GET'
            }),
            providesTags: ['vacancies'],
            transformResponse: ({listObjects}: { listObjects: Vacancy[] }) => {
                return listObjects;
            }
        }),
        getVacancyList: build.query<Option[], { params: string }>({
            query: ({params}) => ({
                url: `/vacancies/staffListOnDate?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({staffList}: { staffList: { [key: string]: Staff[] } }) => {
                const opt = Object.values(staffList).flatMap(i => i).map(item => ({
                    label: item.StaffName,
                    value: item.FStaffs
                }));
                console.log('@@@@@@ ', opt);
                const defaultOpt = {label: 'Все вакансии', value: ''};
                return [defaultOpt, ...opt];
            }
        })
    }), overrideExisting: true
});

export const {
    useLazyGetVacanciesQuery,
    useGetVacancyListQuery,
} = vacanciesApi;
