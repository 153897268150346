import styled from 'styled-components';


export type TooltipPosition = 'left' | 'center' | 'right';

function getPosition(position: TooltipPosition) {
    switch (position) {
        case 'left':
            return `right: 0px;`;
        case 'right':
            return `left: 0px;`;
        default:
            return null;
    }
}

type TooltipContentProps = {
    position: TooltipPosition;
    visible: boolean;
    top: number;
}

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const TooltipToggle = styled.div`
  background-color: var(--white-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 1px solid var(--border-color);
`;

export const TooltipContent = styled.div.withConfig({
    shouldForwardProp: (prop) => !['position', 'visible', 'top'].includes(prop)
})<TooltipContentProps>`
  position: absolute;
  top: ${({top}) => `-${25 * top}px`};
  ${({position}) => getPosition(position)}
  visibility: ${({visible}) => visible ? 'visible' : 'hidden'};
  padding: 3px 7px;
  border-radius: 8px;
  background-color: var(--white-color);
  max-width: 300px;
  min-width: 200px;
  width: fit-content;
  border: 1px solid var(--placeholder);
`;
