import {createSlice} from '@reduxjs/toolkit';
import {AuthState, defaultRegForm, defaultUser} from './types';
import {authApi} from './auth.api';
import {accountApi} from '../../account/store/account.api';

const initialState: AuthState = {
    isAuth: false,
    user: defaultUser,
    form: defaultRegForm
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.isAuth = false;
        },
        setRegForm: (state, {payload}) => {
            state.form.name = payload.name;
            state.form.phone = payload.phone;
            state.form.pwd = payload.pwd;
            state.form.qrContent = payload.qrContent;
            state.form.faceId = payload.faceId;
        },
        byFaceId: (state) => {
            state.isAuth = true;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, {payload}) => {
                if (!payload.status) {
                    state.isAuth = false;
                    state.user = defaultUser;
                } else {
                    state.user = {...state.user, ...payload.user};
                    state.isAuth = true;
                }
            }
        );
        builder.addMatcher(
            authApi.endpoints.registration.matchFulfilled,
            (state, {payload}) => {
                if (!payload.status) {
                    state.isAuth = false;
                    state.user = defaultUser;
                } else {
                    state.user = {...state.user, ...payload.userInfo};
                    state.isAuth = true;
                }
            }
        );
        builder.addMatcher(
            accountApi.endpoints.getUserInfo.matchFulfilled,
            (state, {payload}) => {
                state.user = {...state.user, ...payload};
            }
        );
    }
});

export const {logout, setRegForm, byFaceId} = authSlice.actions;
export default authSlice.reducer;
