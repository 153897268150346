export function getTooltipPosition(element: HTMLElement) {
    const rect = element.getBoundingClientRect(); // Получаем положение элемента на экране
    const screenWidth = window.innerWidth; // Ширина экрана
    const middleAreaStart = screenWidth / 3; // Начало средней зоны экрана
    const middleAreaEnd = (screenWidth / 3) * 2; // Конец средней зоны экрана

    // Определяем позицию в зависимости от того, где элемент находится относительно краев экрана
    if (rect.left < middleAreaStart) {
        return 'right'; // Ближе к левому краю — позиционируем справа
    } else if (rect.right > middleAreaEnd) {
        return 'left'; // Ближе к правому краю — позиционируем слева
    } else {
        return 'center'; // Находится в средней части экрана — позиционируем по центру
    }
}
