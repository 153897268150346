import styled from 'styled-components';
import {Button} from '../../fields';

export const MapContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const TopPanel = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  gap: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  height: 43px;
  padding: 0 10px;
`;

export const Controls = styled.div`
  position: fixed;
  bottom: 30%;
  right: 20px;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 10px;

  & button {
    width: 55px;
    height: 55px;
  }

  & .location-icon {
    transform: rotate(45deg);

  }
`;

export const NavigatePanel = styled.div`
  position: fixed;
  bottom: 90px;
  right: 20px;
  left: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  z-index: 2;
`;

export const VacancyBtn = styled(Button)`
  height: 55px;
  width: 50%;
  max-width: 200px;
`;

export const Back = styled(Button)`
  width: 55px;
  height: 55px;
  padding: 0;
`;


export const BackBtnWrap = styled.div`
  width: 45px;
  height: 45px;
  background-color: #F0F0F0;
  border: 3px solid #ffffff;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListDays = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;
`;

export const ButtonDay = styled.div.withConfig({
    shouldForwardProp: prop => !['active'].includes(prop)
})<{ active: boolean }>`
  background-color: ${({active}) => active ? 'var(--main-color)' : '#FFFFFF'};
  padding: 5px;
  border: 1px solid var(--main-color);
  font-size: 16px;
  font-weight: 400;
  color: ${({active}) => active ? '#ffffff' : 'var(--text-color)'};
  border-radius: 12px;
  text-align: center;

  width: 32.3%;
`;
