import {api} from '../../../store/api';
import {ShiftForm} from './types';

const shiftApi = api.injectEndpoints({
    endpoints: build => ({
        openShift: build.mutation<any, ShiftForm>({
            query: (form) => ({
                url: '/workShift/open',
                method: 'POST',
                body: form
            })
        }),
        closeShift: build.mutation<any, { ID_Shift: string, qrContent: string }>({
            query: (body) => ({
                url: '/workShift/close',
                method: 'POST',
                body
            }),
            invalidatesTags: ['shiftOrders'],
        })
    }), overrideExisting: true
});

export const {useOpenShiftMutation, useCloseShiftMutation} = shiftApi;
