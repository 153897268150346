import {createSlice} from '@reduxjs/toolkit';
import {defaultShiftForm, ShiftState} from './types';

const initialState: ShiftState = {
    form: defaultShiftForm
};

const shiftSlice = createSlice({
    name: 'shift',
    initialState,
    reducers: {
        chaneShiftForm: (state, {payload}) => {
            state.form.isOwnBag = payload.isOwnBag;
            state.form.bagNum = payload.bagNum;
            state.form.transportType = payload.transportType;
            state.form.transportNum = payload.transportNum;
            state.form.medicalNum = payload.medicalNum;
            state.form.qrContent = payload.qrContent;
        }
    }
});

export const {chaneShiftForm} = shiftSlice.actions;
export default shiftSlice.reducer;
