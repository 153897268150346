import React from 'react';
import styled from 'styled-components';
import {Select} from 'antd';
import {Option} from '../store/types';
import {useAppSelector} from '../hooks/hook-store';

interface SelectCustomProps {
    mode: 'multiple' | 'tags';
    disabled: boolean;
    height?: number;
    options: Option[] | undefined;
    defaultValue?: Option;
    handleSelect: (value: string) => void;
}

export const SelectCustom = (
    {
        mode,
        options,
        defaultValue,
        disabled,
        height,
        handleSelect
    }: SelectCustomProps
) => {
    const {filter} = useAppSelector(state => state.shiftOrders);
    const defaultObj = filter.filter(i => i.type === 'FObjects').flatMap(i => i.value);
    return (
        <SelectContainer
            mode={mode}
            allowClear
            placeholder={'Выбрать'}
            options={options as Option[] ?? []}
            disabled={disabled}
            height={height}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
            value={defaultObj}
            onDeselect={(value, option) => {
                handleSelect(value as string);
            }}
            onSelect={(value, option) => {
                handleSelect(value as string);
            }}
        />
    );
};

const SelectContainer = styled(Select).withConfig({
    shouldForwardProp: prop => !['height'].includes(prop)
})<{ height?: number }>`
  display: flex;
  width: 100%;
  min-height: 33px;
  height: ${({height}) => height && height + 'px'};
  border: 1px solid var(--border-color);
  border-radius: 12px;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;
  padding: 0 8px;
`;
