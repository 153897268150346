import {api, BASE_URL} from '../../../store/api';
import {OrderInfo} from './types';
import {RootState} from '../../../store';

const orderApi = api.injectEndpoints({
    endpoints: build => ({
        getOrderDetails: build.query<OrderInfo, { ID_Obj: string; OrderID: string }>({
            query: ({ID_Obj, OrderID}) => ({
                url: `workShift/orderInfo?ID_Obj=${ID_Obj}&OrderID=${OrderID}`
            }),
            providesTags: ['order'],
            keepUnusedDataFor: 0,
        }),
        getProblem: build.query<string[], void>({
            query: () => ({
                url: '/info/problemOptions',
                method: 'GET'
            }),
            transformResponse: ({problemOptions}) => {
                return problemOptions;
            }
        }),
        sendProblem: build.mutation<{ status: boolean }, { OrderID: string, problem: string }>({
            query: ({OrderID, problem}) => ({
                url: '/order/problem',
                method: 'POST',
                body: {OrderID, problem}
            })
        }),
        getQrFeedback: build.mutation<any, { OrderID: string }>({
            queryFn: async ({OrderID}, _queryApi) => {
                try {
                    const state = (_queryApi.getState() as RootState);
                    const id_con = state?.auth?.user?.id_con;
                    const response = await fetch(`${BASE_URL}/order/osCustomer`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'id_con': id_con
                        },
                        body: JSON.stringify({OrderID}),
                    });

                    if (!response.ok) {
                        throw new Error(`Error ${response.status}: ${response.statusText}`);
                    }

                    // Убедимся, что ответ в формате Blob
                    const blob = await response.blob();
                    const imageUrl = URL.createObjectURL(blob);
                    return {data: imageUrl}; // Возвращаем URL
                } catch (error: any) {
                    return {error: error.message || 'Unknown error'};
                }
            }
        }),
        checkedDelivered: build.mutation<{ status: boolean }, { OrderID: string }>({
            query: ({OrderID}) => ({
                url: '/order/delivered',
                method: 'POST',
                body: {OrderID}
            }),
            invalidatesTags: ['order']
        }),
        changePay: build.mutation<{ status: boolean }, { OrderID: string }>({
            query: ({OrderID}) => ({
                url: '/order/changePaymentType',
                method: 'POST',
                body: {OrderID}
            })
        })
    }), overrideExisting: true
});

export const {
    useGetOrderDetailsQuery,
    useLazyGetProblemQuery,
    useSendProblemMutation,
    useGetQrFeedbackMutation,
    useCheckedDeliveredMutation,
    useChangePayMutation
} = orderApi;
