import React from 'react';
import {MainLayout} from '../../layouts';
import {Outlet} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

export const PublicRoute = () => {
    return (
        <MainLayout>
            <Outlet/>
            <ToastContainer position={'top-center'} autoClose={3000}/>
        </MainLayout>
    );
};
