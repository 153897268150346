import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, themes} from '../../utils';
import {useNavigate} from 'react-router-dom';
import {
    Back,
    BackBtnWrap,
    ButtonDay,
    Controls,
    Header,
    ListDays,
    MapContainer,
    NavigatePanel,
    TopPanel,
    VacancyBtn
} from '../map/styled';
import {Map} from '@pbe/react-yandex-maps';
import {Button, Paragraph, RadioButtons} from '../../fields';
import {Icon} from '../../assets/icon';
import {useGetVacancyListQuery, useLazyGetVacanciesQuery} from './store/vacancies.api';
import {RenderOrderPoint} from './components';
import {Vacancy, VacancyPoint} from './store/types';
import dayjs from 'dayjs';
import {Loader} from '../../components/loader';
import {VacancyCard} from './components/VacancyCard';
import {CloseBtn, Modal, ModalBody, ModalHeader} from './styles';

const getDatesForNextWeek = (): string[] => {
    const days = [];
    for (let i = 0; i < 7; i++) {
        const date = dayjs().add(i, 'day');
        days.push(date.format('YYYY-MM-DD'));
    }
    return days;
};

type ParamsData = { [key: string]: string }

const updateField = (data: ParamsData[], key: string, newValue: string) => {
    return data.some(obj => obj[key])
        ? data.map(obj => {
            if (obj[key]) {
                // Если newValue пустая строка, удаляем ключ
                const updatedObj = newValue ? {...obj, [key]: newValue} : {...obj};
                if (!newValue) delete updatedObj[key];
                return updatedObj;
            }
            return obj;
        })
        : newValue
            ? [...data, {[key]: newValue}] // Добавляем новый объект только если newValue не пустая строка
            : data;
};

const days = getDatesForNextWeek();
const defaultParams = [{Start: days[0], Finish: days[0]}];

export const Vacancies = () => {
    const mapRef = useRef<any>(null);
    const navigate = useNavigate();
    const [zoom, setZoom] = useState(10);
    const [ymaps, setYmaps] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showVacancy, setShowVacancy] = useState<boolean>(false);


    const [activeDay, setActiveDay] = useState<string>(days[0]);
    const [staffs, setStaffs] = useState<string>('');

    const [point, setPoint] = useState<VacancyPoint[]>([]);
    const [paramsData, setParamsData] = useState<ParamsData[]>(defaultParams);

    const {data: vacancy} = useGetVacancyListQuery({params: `Start=${activeDay}&Finish=${activeDay}`},
        {refetchOnMountOrArgChange: true});
    const [getVacancyPoints, {data, isFetching}] = useLazyGetVacanciesQuery();

    useEffect(() => {
        const params = paramsData
            .map(obj =>
                Object.entries(obj)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&')
            )
            .join('&');

        getVacancyPoints({params});
    }, [paramsData]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }
    }, [zoom]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19);
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0);
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    mapRef.current.setCenter([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user\'s location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleActiveDay = (day: string) => {
        setActiveDay(day);
        const dataStart = updateField(paramsData, 'Start', day);
        const dataF = updateField(dataStart, 'Finish', day);
        setParamsData(dataF);
    };

    const handleSelectPoint = (list: VacancyPoint[]) => {
        setShowModal(true);
        setPoint(list);
    };

    const selectVacancyFilter = (value: string) => {
        setShowVacancy(false);
        setStaffs(value);
        const updatedData = updateField(paramsData, 'FStaff', value);
        setParamsData(updatedData);
    };

    const onCloseModal = () => {
        showModal && setShowModal(false);
        showVacancy && setShowVacancy(false);

        setPoint([]);
    };

    const renderOrder = useMemo(() => {
        return <>{ymaps && data?.map((item: Vacancy) =>
            <RenderOrderPoint
                key={item.id}
                item={item}
                ymaps={ymaps}
                onClick={() => handleSelectPoint(item.VacancyList)}
            />
        )}</>;
    }, [data, ymaps]);

    const selectVacancyName = vacancy?.find(i => i.value === staffs)?.label ?? 'Вакансии';
    console.log(point);
    return (
        <MapContainer>
            {isFetching ? <Loader type={'large'}/> : null}
            <Map
                defaultState={{zoom: 10, center: [55.755864, 37.617698]}}
                width={window.innerWidth}
                height={window.innerHeight}
                instanceRef={mapRef}
                onLoad={ymaps => {
                    setYmaps(ymaps);
                }}
            >
                <TopPanel>
                    <Header>
                        <Paragraph as={'span'} colorType={'main'} fontSize={16} weight={'400'}>
                            Карта вакансии
                        </Paragraph>
                    </Header>
                    <ListDays>
                        {days.map((item, idx) =>
                            <ButtonDay active={item === activeDay} key={idx}
                                       onClick={() => handleActiveDay(item)}>{item}</ButtonDay>)}
                    </ListDays>
                </TopPanel>
                <Controls>
                    <Button full={false} onClick={handleZoomIn}>
                        <Icon type={'zoomIn'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>

                    <Button full={false} onClick={handleZoomOut}>
                        <Icon type={'zoomOut'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>
                    <Button full={false} onClick={getUserLocation}>
                        <Icon type={'location'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>
                </Controls>
                <NavigatePanel>
                    <VacancyBtn
                        full={true}
                        onClick={() => {
                            setShowVacancy(true);
                        }}
                    >{selectVacancyName}</VacancyBtn>
                    <Back full={false} onClick={() => navigate('/')}>
                        <Icon type={'home'} width={20} height={20} color={themes.colors.borderColor}/>
                    </Back>
                    <Back full={false} onClick={() => navigate(-1)}>
                        <BackBtnWrap>
                            <Icon type={'back'} width={20} height={20} color={themes.colors.borderColor}/>
                        </BackBtnWrap>
                    </Back>
                </NavigatePanel>

                {renderOrder}
            </Map>
            <Modal
                title=""
                placement={'bottom'}
                closable={false}
                height={window.innerHeight - 100}
                onClose={onCloseModal}
                open={showModal}
                key={'bottom'}
            >
                <ModalHeader>
                    <Paragraph as={'span'} fontSize={16} colorType={'main'} weight={'400'}>Вакансии</Paragraph>
                    <CloseBtn onClick={onCloseModal}>
                        <Icon type={'close'} width={20} height={20} color={'#000000'}/>
                    </CloseBtn>
                </ModalHeader>
                <ModalBody>
                    <Box padding={'0'}>
                        {point.map(item => <VacancyCard key={item.ID} item={item}/>)}
                    </Box>
                </ModalBody>
            </Modal>
            <Modal
                title=""
                placement={'bottom'}
                closable={false}
                height={window.innerHeight - 100}
                onClose={onCloseModal}
                open={showVacancy}
                key={'bottom-2'}
            >
                <ModalHeader>
                    <Paragraph as={'span'} fontSize={16} colorType={'main'} weight={'400'}>Выбор вакансии</Paragraph>
                    <CloseBtn onClick={onCloseModal}>
                        <Icon type={'close'} width={20} height={20} color={'#000000'}/>
                    </CloseBtn>
                </ModalHeader>
                <ModalBody>
                    <RadioButtons
                        direction={'column'}
                        options={vacancy ?? []}
                        selected={staffs}
                        onSelected={selectVacancyFilter}/>
                </ModalBody>

            </Modal>
        </MapContainer>
    );
};



