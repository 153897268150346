import styled from 'styled-components';
import {Button} from '../../../fields';

export const Link = styled.a`
  color: blue;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
`;

export const Card = styled.div`
  background-color: #FFFFFF;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  margin-bottom: 20px;
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f3f3f3;
`;

export const CardBody = styled.div`
  padding: 10px;
`;

export const ButtonFeedback = styled(Button)`
  background-color: var(--error-color);
  border-color: var(--error-color);
  color: #ffffff;
  font-size: 16px;
  margin-left: auto;
  margin-top: 20px;
`;
