import {api} from '../../../store/api';
import {LoginForm, RegistrationForm, User} from './types';
import {RootState} from '../../../store';

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        login: build.mutation<{ status: boolean, user: User }, LoginForm>({
            query: (data) => ({
                url: '/authorization/login',
                method: 'POST',
                body: data
            })
        }),
        logout: build.query<void, void>({
            query: () => ({
                url: '/authorization/logout',
                method: 'GET'
            })
        }),
        registration: build.mutation<{ status: boolean, userInfo: User }, RegistrationForm>({
            query: (data) => ({
                url: '/registration/user',
                method: 'POST',
                body: data
            })
        }),
        checkSession: build.query<{ status: boolean }, void>({
            query: () => ({
                url: '/user/checkSession',
                method: 'GET'
            })
        }),
        getKey: build.mutation<any, void>({
            queryFn: async (_args, _queryApi, extraOptions, fetchWithBQ) => {
                const state = (_queryApi.getState() as RootState);
                const Phone = state.auth.user.Phone;


                const result = await fetchWithBQ({
                    url: `/authorization/getHash.php`,
                    method: 'POST',
                    body: {phone: Phone}
                });

                return result.data ? {data: result.data} : {error: result.error};
            },
        })
    }), overrideExisting: true
});

export const {
    useLoginMutation,
    useLazyLogoutQuery,
    useCheckSessionQuery,
    useRegistrationMutation,
    useGetKeyMutation
} = authApi;
