import React from 'react';
import styled from 'styled-components';
import {text} from '../utils';

interface CheckboxProps {
    text?: string;
    textDirection?: 'left' | 'right';
    status: boolean;
    onChange: (status: boolean) => void;
}

export const Checkbox = ({text, onChange, status, textDirection}: CheckboxProps) => {
    return (
        <Label>
            {text && textDirection === 'left' ? <p>{text}</p> : null}
            <input type="checkbox" checked={status} onChange={(e) => onChange(e.target.checked)}/>
            <span></span>
            {text && textDirection === 'right' ? <p>{text}</p> : null}
        </Label>
    );
};

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;

  & input[type="checkbox"] {
    display: none;
  }

  & span {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 2px solid var(--border-color);
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    transition: background-color 0.3s;
    background-color: var(--white-color);
  }

  & input[type="checkbox"]:checked + span {
    background-color: var(--border-color);
    border-color: var(--border-color);
  }

  & input[type="checkbox"]:checked + span::after {
    content: '';
    position: absolute;
    left: 4px;
    top: -1px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  & p {
    margin: 0;
    color: var(--white-color);
    line-height: 16px;
     ${text('400')};
    width: calc(100% - 20px);
  }
`;
