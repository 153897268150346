import styled from 'styled-components';
import {text} from '../utils';

type InputProps = {
    isError?: boolean;
    height?: number;
}

export const Input = styled.input.attrs((props) => ({
    as: props.as || 'input'
})).withConfig({
    shouldForwardProp: (prop) => !['isError', 'height'].includes(prop)
})<InputProps>`
   ${text('400')};
  border-radius: 12px;
  border: 1px solid ${(props) => (props.isError ? 'var(--error-color)' : 'var(--border-color)')};
  width: 100%;
  height: ${({height}) => height ? height : 40}px;
  background-color: ${(props) => (props.isError ? 'rgba(252, 0, 0, 0.36)' : 'var(--white-color)')};

  &::placeholder {
    color: var(--placeholder);
  }
`;
