import React from 'react';
import {DatePicker, TimeRangePickerProps} from 'antd';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import styled from 'styled-components';
import {text} from '../utils';
import {useAppSelector} from '../hooks/hook-store';
import {ShiftOrdersFilter} from '../screens/shiftOrders/store/types';


dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const {RangePicker} = DatePicker;
// Сегодня
// Вчера
// Эта неделя
// Пред неделя
// Этот мес
// Пред мес

export const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Сегодня', value: [dayjs().startOf('day'), dayjs().endOf('day')]},
    {label: 'Вчера', value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')]},
    {
        label: 'Эта неделя',
        value: [dayjs().startOf('week').add(1, 'day'), dayjs().endOf('week').add(1, 'day')]
    },
    {
        label: 'Пред неделя',
        value: [dayjs().subtract(1, 'week').startOf('week').add(1, 'day'), dayjs().subtract(1, 'week').endOf('week').add(1, 'day')]
    },
    {label: 'Этот мес', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
    {
        label: 'Пред мес',
        value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
    },
];

interface PickerProps {
    height?: number;
    onChange: (dateStrings: string[]) => void;
}

export const Picker = ({onChange, height}: PickerProps) => {
    const {filter} = useAppSelector(state => state.shiftOrders);

    const dateStart = filter.find(k => k.type === 'Start') as ShiftOrdersFilter;
    const dateFinish = filter.find(k => k.type === 'Finish') as ShiftOrdersFilter;

    return (
        <StyledDatePicker
            locale={ru_RU}
            format="YYYY-MM-DD"
            presets={rangePresets}
            height={height}
            value={[dayjs(dateStart.value as string, 'YYYY-MM-DD'), dayjs(dateFinish.value as string, 'YYYY-MM-DD')]}
            onChange={(dates, dateStrings) => onChange(dateStrings)}
            separator="-"
            allowClear={false}
        />
    );
};


const StyledDatePicker = styled(RangePicker).withConfig({
    shouldForwardProp: prop => !['height'].includes(prop)
})<{ height?: number }>`
  width: 100%;
  height: ${({height}) => height ? height : 33}px;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 12px;

  .ant-picker-input > input {
    color: #333;
    font-size: 16px;
    ${() => text('400')}
  }

  .ant-picker-suffix {
    color: var(--border-color);
  }

  &:hover, &.ant-picker-focused {
    border-color: var(--border-color);
  }

  .ant-picker-separator {
    color: #8c8c8c;
    font-size: 16px;
  }

  .ant-picker-presets {
    min-width: 100px !important;
    max-width: 150px !important;
  }

  .ant-picker-active-bar {
    background: var(--border-color);
  }

  /* Ограничение размеров выпадающего календаря */

  .ant-picker-dropdown {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 100% !important;
    max-width: 350px;
    max-height: 60vh;
    left: 0 !important;
    top: 0 !important;
    overflow-y: auto; /* Добавляем прокрутку */

    .ant-picker-cell {
      font-size: 14px;
      color: #333;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: #1890ff;
      color: #fff;
    }
  }

  /* Медиа-запрос для мобильных устройств */
  @media (max-width: 768px) {
    .ant-picker-dropdown {
      max-width: 90vw; /* Занимает до 90% экрана */
      max-height: 60vh;
      font-size: 14px;
    }
  }
`;
