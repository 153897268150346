import React from 'react';
import {Placemark} from '@pbe/react-yandex-maps';
import {Vacancy} from '../store/types';

const deliveryIcon = require('../../../assets/images/icons/delivery-icon.png');

interface RenderOrderPointProps {
    item: Vacancy;
    ymaps: any;
    onClick: () => void;
}


export const RenderOrderPoint = ({item, onClick, ymaps}: RenderOrderPointProps) => {

    return (
        <>
            <Placemark
                geometry={[item.lat, item.lon]}
                options={{
                    iconImageHref: '',
                    iconOffset: [-25, -55],
                    iconImageSize: [30, 30],
                    iconLayout: ymaps.templateLayoutFactory.createClass(
                        `<div style="padding: 5px; 
width: fit-content; 
display: flex; 
align-items: center; 
border: 1px solid red;
border-radius: 4px;
height: 15px; 
background-color: #ffffff;">
<p style="font-size: 10px; white-space: nowrap;">${item.qntVacancy}</p>
</div>`,
                        {
                            build: function () {
                                // Вызываем метод build родительского класса
                                this.constructor.superclass.build.call(this);
                                // Получаем ссылку на контейнер метки
                                const element = this.getParentElement();
                                // Если элемент существует, скрываем стандартную иконку
                                if (element) {
                                    const icon = element.querySelector('.ymaps-icon');
                                    if (icon) {
                                        icon.style.display = 'none';
                                    }
                                }
                            }
                        }
                    ),
                }}
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            />
            <Placemark
                geometry={[item.lat, item.lon]}
                options={{
                    iconLayout: 'default#image',
                    iconImageHref: deliveryIcon,
                    iconOffset: [0, 0],
                    iconImageSize: [30, 30],

                }}
                onClick={onClick}
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            />
        </>
    );
};
