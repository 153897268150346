import React from 'react';
import styled from 'styled-components';
import {text} from '../../../utils';


export const DateTag = ({text}: { text: string }) => {
    return (
        <Tag>
            <Line/>
            <Text>{text}</Text>
            <Line/>
        </Tag>
    );
};

const Tag = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  flex-grow: 1;
  height: 1px;
  background-color: var(--main-color);

  &:first-of-type {
  }

`;

const Text = styled.p`
   ${text('400')};
  font-size: 12px;
  color: var(--main-color);
  margin: 0 10px;
`;
