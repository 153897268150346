import styled from 'styled-components';

type ContainerType = {
    flexGrow?: 0 | 1;
    flexDirection?: 'row' | 'column'
}

type BoxType = {
    padding?: string;
    flex?: boolean;
    flexDirection?: 'row' | 'column'
}

type ColProps = {
    span?: number;
    md?: number;
    lg?: number;
    direction?: 'flex-end' | 'center'
};

export const Container = styled.div.withConfig({
    shouldForwardProp: (prop) => !['flexGrow', 'flexDirection'].includes(prop),
})<ContainerType>`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection};
  align-items: center;
  justify-content: center;
  flex-grow: ${({flexGrow}) => flexGrow};
`;

export const Form = styled.form`
  width: 100%;
`;

export const Box = styled.div.withConfig({
    shouldForwardProp: (prop) => !['flex', 'flexDirection', 'padding'].includes(prop),
})<BoxType>`
  padding: ${({padding}) => padding ? padding : '10px'};
  width: 100%;
  display: ${({flex}) => flex ? 'flex' : 'block'};
  ${({flex, flexDirection}) => flex ? 'flex-direction:' + flexDirection : null}
}
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const Col = styled.div.withConfig({
    shouldForwardProp: (prop) => !['span', 'md', 'lg', 'direction'].includes(prop)
})<ColProps>`
  flex: 0 0 ${({span}) => (span ? (span / 12) * 100 : 100)}%;
  max-width: ${({span}) => (span ? (span / 12) * 100 : 100)}%;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: ${({direction}) => direction ? direction : 'flex-start'};

  ${({md}) => md && `
    @media (min-width: 768px) {
      flex: 0 0 ${(md / 12) * 100}%;
      max-width: ${(md / 12) * 100}%;
    }
  `}

  ${({lg}) => lg && `
    @media (min-width: 992px) {
      flex: 0 0 ${(lg / 12) * 100}%;
      max-width: ${(lg / 12) * 100}%;
    }
  `}
`;

export const CardContainer = styled.div`
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  background-color: var(--white-color);
  margin-bottom: 20px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid var(--placeholder);
`;
