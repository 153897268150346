import React, {MouseEvent} from 'react';
import {CardContainer, Col, Row, Separator, text, themes} from '../../../utils';
import {Paragraph} from '../../../fields';
import styled from 'styled-components';
import {Icon} from '../../../assets/icon';
import {IconType} from '../../../assets/icon/Icon';
import {useNavigate} from 'react-router-dom';
import {ShiftOrdersList} from '../../shiftOrders/store/types';

const Item = ({iconType, text, fontSize}: { iconType: IconType; text: string, fontSize: number; }) => {
    return (
        <>
            <Icon width={15} height={15} type={iconType} color={themes.colors.textColor}/>
            <Separator space={5}/>
            <Paragraph as={'span'} fontSize={fontSize} weight={'400'}
                       colorType={'main'}>
                {text}
            </Paragraph>
        </>
    );
};


export interface CardProps {
    item: ShiftOrdersList;
}

export const Card = ({item}: CardProps) => {
    const navigate = useNavigate();

    const handleCloseShift = (e: MouseEvent<HTMLButtonElement>, ID_Shift: string) => {
        e.stopPropagation();
        navigate(`/scanner`, {state: {pathFrom: '/', ID_Shift}});
    };

    const jumpToOrders = () => {
        navigate(`/shift-orders/${item.ID}`, {state: {ObjName: item.ObjName, data: item.ShiftDate}});
    };

    return (
        <CardContainer onClick={jumpToOrders}>
            <Row style={{marginBottom: '8px'}}>
                <Col span={6}>
                    <Item iconType={'bank'} text={item.ObjName} fontSize={14}/>
                </Col>
                <Col span={6} direction={'flex-end'}>
                    <Item iconType={'calendar'} text={item.ShiftDate} fontSize={14}/>
                </Col>
            </Row>
            <Row style={{marginBottom: '8px'}}>
                <Col span={4}>
                    <Item iconType={'clock'} text={`Пришел: ${item.workStartTime}`} fontSize={10}/>
                </Col>
                <Col span={4} direction={'center'}>
                    <Item iconType={'clock'} text={`Ушел: ${item.workEndTime ?? '--:--'}`} fontSize={10}/>
                </Col>
                <Col span={4} direction={'flex-end'}>
                    <Item iconType={'clock'} text={`Отработал:  ${item.workedTime ?? '--:--'}`}
                          fontSize={10}/>
                </Col>
            </Row>
            <Row style={{marginBottom: '8px'}}>
                <Col span={6}>
                    <Item iconType={'auto'} text={`Доставил заказов: ${item.ordersDelivered ?? 0}`} fontSize={10}/>
                </Col>
                <Col span={6} direction={'flex-end'}>
                    <Item iconType={'chart_pie'} text={`Утилизация: ${item.utilization ?? 0}`} fontSize={10}/>
                </Col>
            </Row>
            <Row style={{marginBottom: '8px'}}>
                <Col span={8}>
                    <Item iconType={'clock_plus'} text={`Среднее время доставки: ${item.avgDeliveryDuration ?? 0} мин`}
                          fontSize={10}/>
                </Col>
                <Col span={4} direction={'flex-end'}>
                    <Item iconType={'road'} text={`Километраж: ${item.utilization ?? '--'}`} fontSize={10}/>
                </Col>
            </Row>
            <Row style={{marginBottom: '8px'}}>
                <Col span={6}>
                    <Item iconType={'user_simple'} text={item.iikoCourierName} fontSize={14}/>
                </Col>
                <Col span={6} direction={'flex-end'}>
                    {item.workEndTime === null
                        ? <ButtonActionShift
                            status={'open'}
                            onClick={(event) => handleCloseShift(event, String(item.ID))}>Закрыть
                            смену</ButtonActionShift>
                        : <Paragraph
                            as={'span'}
                            fontSize={12}
                            weight={'300'}
                            colorType={'second'}>Закрыта</Paragraph>
                    }
                </Col>
            </Row>

        </CardContainer>
    );
};


const ButtonActionShift = styled.button.withConfig({
    shouldForwardProp: (prop) => !['status'].includes(prop)
})<{ status: 'close' | 'open' }>`
  border: none;
  outline: none;
  border-radius: 12px;
  padding: 3px 10px;
  background-color: #70823833;
  min-width: 150px;
  ${text('500')};
  color: var(--text-color)
}

;
`;


