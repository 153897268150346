import React, {useEffect, useRef, useState} from 'react';
import {Map} from '@pbe/react-yandex-maps';
import {OrderInfo} from '../store/types';
import {Box, Col, Row, Separator, themes} from '../../../utils';
import {Button, Paragraph} from '../../../fields';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {Icon} from '../../../assets/icon';

const splitTime = (date: string) => {
    if (!date) return '';
    return date.split(' ')[1];
};

const getTimeRange = (date1: string, date2: string) => {
    if (!date1 || !date2) return '';

    const d1 = dayjs(date1, 'DD.MM.YYYY HH:mm');
    const d2 = dayjs(date2, 'DD.MM.YYYY HH:mm');
    const totalMinutes = d2.diff(d1, 'minute');
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours === 0) {
        return `${minutes} мин`;
    }

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

const Item = ({text, subtext, col1, col2}: { text: string, subtext: string, col1: number, col2: number }) => {
    return (
        <Row>
            <Col span={col1}>
                <Paragraph as={'span'} weight={'400'} colorType={'main'}
                           fontSize={14}>{subtext}</Paragraph>
            </Col>
            <Col span={col2} direction={'flex-end'}>
                <Paragraph as={'span'} textPosition={'right'} weight={'400'} colorType={'main'}
                           fontSize={14}>{text}</Paragraph>
            </Col>
        </Row>
    );
};

export const Delivery = ({item}: { item: OrderInfo }) => {
    const mapRef = useRef<any>(null);
    const object = item.objectInfo;
    const [ymaps, setYmaps] = useState<any>();

    const [carTime, setCarTime] = useState<string>('');
    const [bikeTime, setBikeTime] = useState<string>('');
    const [pedestrianTime, setPedestrianTime] = useState<string>('');
    const [zoom, setZoom] = useState(16);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }

    }, [zoom]);

    useEffect(() => {
        if (ymaps && mapRef.current && item && item.address['2']) {
            const address = [item.address['2'].coordinates.latitude, item.address['2'].coordinates.longitude];
            const object = [+item.objectInfo.Latitude, +item.objectInfo.Longitude];

            const carRoute = new ymaps.multiRouter.MultiRoute({
                    referencePoints: [
                        object, // Первая точка
                        address // Вторая точка
                    ],
                    params: {
                        // Параметры для первого маршрута
                        routingMode: 'auto', // Для автомобиля
                        avoid: [],
                        avoidTrafficJams: true,
                        results: 1
                    }
                }
                // , {
                //     boundsAutoApply: true
                // }
            );


            mapRef.current.geoObjects.add(carRoute);

            // Создаем мультимаршрут для велосипеда
            const bikeRoute = new ymaps.multiRouter.MultiRoute({
                    referencePoints: [
                        object, // Первая точка
                        address // Вторая точка
                    ],
                    params: {
                        routingMode: 'bicycle', // Для велосипеда
                    }
                }
                // , {
                //     boundsAutoApply: true
                // }
            );
            mapRef.current.geoObjects.add(bikeRoute);

            // Пример для пешеходного маршрута
            const pedestrianRoute = new ymaps.multiRouter.MultiRoute({
                    referencePoints: [
                        object, // Первая точка
                        address // Вторая точка
                    ],
                    params: {
                        routingMode: 'pedestrian', // Для пешеходов
                    }
                }
                // , {
                //     boundsAutoApply: true
                // }
            );

            // Добавляем пешеходный маршрут на карту
            mapRef.current.geoObjects.add(pedestrianRoute);

            // Обработчик события для мультимаршрута
            carRoute.model.events.add('requestsuccess', () => {
                const activeRoute = carRoute.getActiveRoute();
                if (activeRoute) {
                    const duration = activeRoute.properties.get('duration').text;
                    setCarTime(duration);
                }
            });

            // Обработчик события для велосипедного маршрута
            bikeRoute.model.events.add('requestsuccess', () => {
                const activeRoute = bikeRoute.getActiveRoute();
                if (activeRoute) {
                    const duration = activeRoute.properties.get('duration').text; // Время в пути на велосипеде

                    setBikeTime(duration);
                }
            });

            // Обработчик события для пешеходного маршрута
            pedestrianRoute.model.events.add('requestsuccess', () => {
                const activeRoute = pedestrianRoute.getActiveRoute();
                if (activeRoute) {
                    const duration = activeRoute.properties.get('duration').text;

                    setPedestrianTime(duration);
                }
            });
        }
    }, [ymaps, item]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };

    const factDelivery = getTimeRange(item.orderInfo.DateSended, item.orderInfo.DateDelivered);

    return (
        <Card>
            <CardHeader>
                <Item col1={4} col2={8} text={item.address['1'] ?? '---'} subtext={'Адрес:'}/>
            </CardHeader>
            <Box padding={'5px 10px 10px'}>
                <Item col1={6} col2={6} text={item.courier.name ?? '---'} subtext={'Курьер:'}/>
                <Item col1={6} col2={6} text={item.courier.phone ?? '---'} subtext={'Тел:'}/>
                <Separator space={5}/>
                <Item col1={8} col2={4} text={splitTime(item.orderInfo.DateSended) ?? '---'}
                      subtext={'Время отправки:'}/>
                <Separator space={5}/>
                <Item col1={8} col2={4} text={carTime} subtext={'Время в пути на автомобиле:'}/>
                <Item col1={8} col2={4} text={bikeTime} subtext={'Время в пути на велосипеде:'}/>
                <Item col1={8} col2={4} text={pedestrianTime} subtext={'Время в пути пешком:'}/>
                <Separator space={5}/>
                <Item col1={8} col2={4} text={splitTime(item.orderInfo.DateDelivered)} subtext={'Доставлено:'}/>
                <Item col1={8} col2={4} text={String(factDelivery)} subtext={'Доставлено за:'}/>
                {object.Latitude !== null && object.Longitude !== null
                    ?
                    <MapWrapper>
                        <Map
                            defaultState={{zoom, center: [+object.Latitude, +object.Longitude]}}
                            width={window.innerWidth > 600 ? 560 : window.innerWidth - 60}
                            height={400}
                            instanceRef={mapRef}
                            onLoad={ymaps => {
                                setYmaps(ymaps);
                            }}
                        />
                        <Controls>
                            <Button full={false} style={{marginBottom: '5px'}} onClick={handleZoomIn}>
                                <Icon type={'zoomIn'} width={20} height={20} color={themes.colors.borderColor}/>
                            </Button>

                            <Button full={false} onClick={handleZoomOut}>
                                <Icon type={'zoomOut'} width={20} height={20} color={themes.colors.borderColor}/>
                            </Button>

                        </Controls>
                    </MapWrapper>
                    : null}
            </Box>
        </Card>
    );
};

const Card = styled.div`
  background-color: var(--white-color);
  border-radius: 8px;
`;

const CardHeader = styled.div`
  border-bottom: 1px solid var(--placeholder);
  padding: 10px 10px 5px;
`;


const MapWrapper = styled.div`
  position: relative;
`;

const Controls = styled.div`
  position: absolute;
  top: 40%;
  right: 10px;
`;
