import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../hooks/hook-store';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import {Footer} from '../../components/footer';
import {MainContainer} from '../../layouts/MainLayout';
import {useCheckSessionQuery} from '../auth/store/auth.api';
import {useSubscribeMutation} from '../../store/api';
import {toast} from 'react-toastify';
import {Drawer} from 'antd';
import {Button, Paragraph} from '../../fields';
import {Separator} from '../../utils';
import styled from 'styled-components';

function urlBase64ToUint8Array(base64String: any) {

    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData: any = window.atob(base64);
    return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
}

const excludeFooterInRoutes: string[] = ['/map', '/vacancies'];

export const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {isAuth, user} = useAppSelector(state => state.auth);
    useCheckSessionQuery(undefined, {pollingInterval: 60000});
    const [subscribe] = useSubscribeMutation();
    const [isPermissionNotification, setIsPermissionNotification] = useState<boolean>(false);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'NOTIFICATION_CLICK') {
                    const {data} = event.data;
                    // Переходим по URL или обрабатываем данные другим способом
                    //window.location.href = url;
                    navigate(`/order/${data.params.OrderID}?ID_Obj=${data.params.ID_Obj}`);
                }
            });
        }
        if ('Notification' in window) {
            const timerId = setTimeout(() => {
                const permission = Notification.permission;

                if (permission === 'granted') {
                    createSubscribe();
                } else {
                    setIsPermissionNotification(true);
                }
            }, 3000);

            return () => {
                clearTimeout(timerId);
            };
        }

    }, []);

    const checkAndSubscribe = async () => {
        if ('Notification' in window) {
            const permission = await Notification.requestPermission();

            if (permission === 'granted') {
                if (!process.env.REACT_APP_PUSH_PUBLIC_KEY) return;
                createSubscribe();
            } else {
                setIsPermissionNotification(true);
            }
        }
    };

    const createSubscribe = async () => {
        const registration = await navigator.serviceWorker.ready;
        const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY);
        const subscription = await registration.pushManager.getSubscription();

        if (!subscription) {
            try {
                const newSubscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidKey,
                });
                await subscribe({newSubscription, id_con: user.notificationToken});
                setIsPermissionNotification(false);
            } catch (error) {
                toast.error('Ошибка при подписке на уведомления');
                setIsPermissionNotification(true);
            }
        }
    };

    return isAuth
        ? (<MainLayout>
            <MainContainer>
                <Outlet/>
                <Drawer
                    title="Подключить уведомления"
                    placement={'bottom'}
                    closable={false}
                    height={200}
                    onClose={() => {
                        setIsPermissionNotification(false);
                    }}
                    open={isPermissionNotification}
                    key={'bottom'}
                >
                    <Paragraph as={'p'} weight={'400'} colorType={'main'} fontSize={15} textPosition={'center'}>
                        Для корректной работы приложения необходимо разрешить уведомления.
                    </Paragraph>
                    <Separator space={10}/>
                    <ActionButton full={true} fontSize={16} onClick={checkAndSubscribe}>Подключить</ActionButton>
                </Drawer>
            </MainContainer>
            {excludeFooterInRoutes.includes(location.pathname) ? null : <Footer/>}
        </MainLayout>)
        : <Navigate to={'/auth/login'}/>;
};


const ActionButton = styled(Button)`
  background-color: var(--main-color);
  color: #ffffff;
`;
