import React, {useState} from 'react';
import {Box, Col, Container, Row, Separator} from '../../utils';
import {Button, Paragraph} from '../../fields';
import {useAppDispatch, useAppSelector} from '../../hooks/hook-store';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {useLazyLogoutQuery} from '../auth/store/auth.api';
import {Drawer} from 'antd';
import {logout} from '../auth/store/auth.slice';

const defaultUserImage = require('../../assets/images/default-user.png');
const formatPhoneNumber = (number: string) => {
    return number.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2-$3-$4-$5');
};


export const Account = () => {
    const dispatch = useAppDispatch();
    const [logoutUser] = useLazyLogoutQuery();
    const {user} = useAppSelector(state => state.auth);

    const [open, setOpen] = useState<boolean>(false);
    console.log(user);
    const handleLogout = () => {
        setOpen(true);
    };

    const handleUpdate = () => {
        window.location.reload();
    };

    function copyToClipboard() {
        navigator.clipboard.writeText(user.notificationToken)
            .then(() => {
                toast.info('Текст скопирован в буфер обмена');
            })
            .catch((err) => {
                console.error('Ошибка при копировании в буфер обмена:', err);
            });
    }

    const confirmLogout = () => {
        logoutUser()
            .unwrap()
            .then(() => {
                dispatch(logout());
            });
    };

    return (
        <>
            <Container>
                <Box style={{flexGrow: 1}}>
                    <Separator space={50}/>
                    <ImageWrapper>
                        {user.Avatar !== '' ? <Image src={defaultUserImage} alt={'user-avatar'}/> : null}
                    </ImageWrapper>
                    <Separator space={20}/>
                    <Row>
                        <Col span={3}>
                            <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Имя</Paragraph>
                        </Col>
                        <Col span={9}>
                            <Field>
                                <Paragraph as={'p'} colorType={'main'} weight={'400'}
                                           fontSize={16}>{user.name}</Paragraph>
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3}>
                            <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Email</Paragraph>
                        </Col>
                        <Col span={9}>
                            <Field>
                                <Paragraph as={'p'} colorType={'main'} weight={'400'}
                                           fontSize={16}>{user.email ?? '--'}</Paragraph>
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3}>
                            <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Телефон</Paragraph>
                        </Col>
                        <Col span={9}>
                            <Field>
                                <Paragraph as={'p'} colorType={'main'} weight={'400'}
                                           fontSize={16}>{formatPhoneNumber(user.Phone) ?? '--'}</Paragraph>
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <Paragraph as={'p'} colorType={'main'} weight={'400'}
                                       fontSize={14}>Скопировать</Paragraph>
                        </Col>
                        <Col span={8}>
                            <Button full={true} fontSize={14} onClick={copyToClipboard}>
                                В буфер обмена
                            </Button>
                        </Col>
                    </Row>

                </Box>
            </Container>
            <Tools>
                <Row>
                    <Col span={6}>
                        <Button
                            full={true}
                            fontSize={16}
                            height={50}
                            onClick={handleUpdate}>
                            Обновить</Button>
                    </Col>
                    <Col span={6}>
                        <Button
                            full={true}
                            fontSize={16}
                            height={50}
                            onClick={handleLogout}>
                            Выход</Button>
                    </Col>
                </Row>
                <Separator space={15}/>
                <Paragraph
                    as={'p'}
                    fontSize={14}
                    colorType={'main'}
                    textPosition={'center'}
                    weight={'400'}>
                    Версия приложения - {process.env.REACT_APP_BUILD_VERSION}
                </Paragraph>
            </Tools>
            <Drawer
                title="Подтвердите выход"
                placement={'bottom'}
                closable={false}
                height={300}
                onClose={() => setOpen(false)}
                open={open}
                key={'bottom'}
            >
                <Row>
                    <Col span={6}>
                        <Button full={true} fontSize={16} onClick={() => setOpen(false)}>Нет</Button>
                    </Col>
                    <Col span={6}>
                        <LogoutButton full={true} fontSize={16} onClick={confirmLogout}>
                            Да
                        </LogoutButton>
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};


const Field = styled.div`
  padding: 3px 10px;
  border-radius: 4px;
  background-color: rgba(196, 196, 196, 0.37);
  width: 100%;
`;

const Tools = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 10px 50px;
`;

const ImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--border-color);
  background-image: url(${defaultUserImage});
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: 220px 220px;
  background-color: #7c8367;
  margin: 0 auto;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const LogoutButton = styled(Button)`
  background-color: var(--main-color);
  color: #ffffff;
`;
