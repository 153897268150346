import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import {Auth, Registration} from './screens/auth';
import {PrivateRoute, PublicRoute} from './screens/routes';
import {Home} from './screens/home';
import {ScannerPage} from './screens/scanner';
import {Account} from './screens/account';
import {Order} from './screens/order';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Shift} from './screens/shift';
import {ShiftOrders} from './screens/shiftOrders';
import {YMaps} from '@pbe/react-yandex-maps';
import {Drawer} from 'antd';
import {Box} from './utils';
import {Paragraph} from './fields';
import {isSafariOnIphone} from './helpers';
import {Vacancies} from './screens/vacancies';
import {MapCustom} from './screens/map';

dayjs.locale('ru');
dayjs.extend(customParseFormat);


function App() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const appHeight = () => {
            setTimeout(() => {
                const doc = document.documentElement;
                doc.style.setProperty('--app-height', `${window.innerHeight}px`);
            }, 100);
        };
        window.addEventListener('resize', appHeight);
        appHeight();
    }, []);


    useEffect(() => {
        const isSafari = isSafariOnIphone();
        if (!isSafari) {
            setShowModal(true);
        }
    }, []);


    return (
        <YMaps
            query={
                {
                    lang: 'ru_RU',
                    apikey: '0adbb008-3071-4219-9486-f3377b7a8089',
                    load: 'templateLayoutFactory,route,multiRouter.MultiRoute,control.RoutePanel'
                }
            }>

            <Routes>
                <Route path={'/auth'} Component={PublicRoute}>
                    <Route path={'/auth/login'} Component={Auth}/>
                    <Route path={'/auth/registration'} Component={Registration}/>
                </Route>
                <Route path={''} Component={PrivateRoute}>
                    <Route path={'/'} Component={Home}/>
                    <Route path={'/shift-orders/:id'} Component={ShiftOrders}/>
                    <Route path={'/order/:id'} Component={Order}/>
                    <Route path={'/shift'} Component={Shift}/>
                    <Route path={'/order/:id'} Component={Order}/>
                    <Route path={'/account'} Component={Account}/>
                    <Route path={'/vacancies'} Component={Vacancies}/>
                    <Route path={'/map'} Component={MapCustom}/>
                </Route>
                <Route path={'/scanner'} Component={ScannerPage}/>
            </Routes>
            <Drawer
                title="Подключите уведомления."
                placement={'bottom'}
                closable={false}
                height={240}
                onClose={() => setShowModal(false)}
                open={showModal}
                key={'bottom'}
            >
                <Box>
                    <Paragraph as={'p'} weight={'600'} colorType={'main'} fontSize={20}>
                        Для полноценной работы приложения на iPhone используйте браузер Safari.
                    </Paragraph>
                </Box>
            </Drawer>
        </YMaps>
    );
}

export default App;
