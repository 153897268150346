export const defaultShiftForm: ShiftForm = {
    isOwnBag: 0,
    bagNum: '',
    transportType: 'Пеший',
    transportNum: '',
    medicalNum: '0',
    qrContent: '',
};

export type ShiftState = {
    form: ShiftForm
}

export type ShiftForm = {
    isOwnBag: number;
    bagNum: string;
    transportType: string;
    transportNum: string;
    medicalNum: string;
    qrContent: string;
}
