import React from 'react';
import {Navigate, NavigateItem} from './styles';
import {useNavigate} from 'react-router-dom';

type AuthNavigateProps = {
    href: string;
    subtext: string;
    text: string;
}

export const AuthNavigate = ({href, subtext, text}: AuthNavigateProps) => {
    const navigate = useNavigate();

    return (
        <Navigate>
            <NavigateItem as={'span'}>{subtext}</NavigateItem>
            <NavigateItem as={'span'} onClick={() => navigate(href)}>{text}</NavigateItem>
        </Navigate>
    );
};
