import styled from 'styled-components';
import {text} from '../../../utils';

export const Navigate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const NavigateItem = styled.a.attrs((props) => ({
    as: props.as || 'a',
}))`
  color: var(--white-color);
   ${text('400')};
  text-decoration: none;
  display: inline-block;
  margin: 0 10px;
`;
