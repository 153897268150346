import {createSlice} from '@reduxjs/toolkit';
import {ShiftOrdersFilter, ShiftOrdersState} from './types';
import dayjs from 'dayjs';

const today = dayjs().format('YYYY-MM-DD');

export const defaultWorkShipFilter: ShiftOrdersFilter[] = [
    {type: 'Start', value: today},
    {type: 'Finish', value: today}
];

const initialState: ShiftOrdersState = {
    filter: defaultWorkShipFilter
};

const shiftOrdersSlice = createSlice({
    name: 'shiftOrders',
    initialState,
    reducers: {
        setWorkShiftFilter: (state, {payload}) => {
            state.filter = payload;
        }
    }
});

export const {setWorkShiftFilter} = shiftOrdersSlice.actions;
export default shiftOrdersSlice.reducer;
