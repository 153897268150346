import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError,} from '@reduxjs/toolkit/query/react';
import {getGlobalAbortController} from './abortController';
import {Option} from './types';
import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export const BASE_URL: string = 'https://apifoodbox.ru/apiCouriersApp';

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'same-origin',
    prepareHeaders: (headers, {getState}) => {
        const state = getState() as any;

        if (state?.auth?.user?.id_con) {
            headers.set('id_con', `${state?.auth?.user.id_con}`);
        }
        return headers;
    },
    fetchFn: async (input, init) => {
        try {
            const response = await fetch(input, {
                ...init,
                signal: getGlobalAbortController().signal,
            });

            if (response.ok && response.headers.get('Content-Type')?.startsWith('image/')) {
                const blob = await response.blob();
                return new Response(blob); // Вернуть Blob как Response
            }

            return response;
        } catch (error: any) {
            if (error.name === 'AbortError') {
                // console.log('Fetch aborted');
                // Возможно, вы захотите выполнить дополнительные действия при прерывании запроса
            } else {
                //   console.error('Fetch error:', error);
            }
            throw error;
        }
    },
});
//
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // const state = api.getState() as any;
    }

    if (result.error && result.error.data) {

    }

    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['shiftOrders', 'order', 'vacancies'],
    refetchOnFocus: true,
    endpoints: build => ({
        getObjects: build.query<Option[], void>({
            query: () => ({
                url: '/filtersData/workShiftObjects',
                method: 'GET'
            }),
            transformResponse: ({listObject}: { listObject: { ID_Obj: number; ObjName: string }[] }) => {
                return listObject.map(item => ({label: item.ObjName, value: String(item.ID_Obj)}));
            }
        })
    })
});

export const apiPush = createApi({
    reducerPath: 'api_push',
    baseQuery: fetchBaseQuery({baseUrl: 'https://api.ifbx.ru/api/v1'}),
    endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
        subscribe: build.mutation<{ message: string }, { newSubscription: PushSubscription, id_con: string }>({
            query: ({newSubscription, id_con}) => ({
                url: '/subscribe',
                method: 'POST',
                body: {newSubscription, id_con}
            })
        })
    })
});

export const {useSubscribeMutation} = apiPush;

export const {useGetObjectsQuery} = api;
