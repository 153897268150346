import {api} from '../../../store/api';
import {RootState} from '../../../store';
import {ShiftOrder, ShiftOrdersList} from './types';

const shiftOrdersApi = api.injectEndpoints({
    endpoints: build => ({
        getWorkShift: build.query<any, void>({
            queryFn: async (_args, _queryApi, extraOptions, fetchWithBQ) => {
                const state = (_queryApi.getState() as RootState);
                const filter = state.shiftOrders.filter;

                const params = filter.map(item => (`${item.type}=${item.value}`)).join('&');
                const result = await fetchWithBQ({
                    url: `/workShift/list?${params}`,
                    method: 'GET',
                });

                return result.data ? {data: result.data as { listWorkShift: ShiftOrdersList[] }} : {error: result.error};
            },
            providesTags: ['shiftOrders']
        }),
        getShiftOrders: build.query<ShiftOrder[], { ID_Shift: string, FStatuses: string }>({
            query: ({ID_Shift, FStatuses}) => ({
                url: `/workShift/orders?ID_Shift=${ID_Shift}&FStatuses=${FStatuses}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({listOrders}) => {
                return listOrders;
            }
        })
    }), overrideExisting: true
});

export const {useGetWorkShiftQuery, useGetShiftOrdersQuery, useLazyGetShiftOrdersQuery} = shiftOrdersApi;
