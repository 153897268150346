import {Action, combineReducers, configureStore, Reducer, ThunkAction} from '@reduxjs/toolkit';
import authSlice, {logout} from '../screens/auth/store/auth.slice';
import {persistReducer, persistStore} from 'redux-persist';
// @ts-ignore
import {encryptTransform} from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import {AuthState, defaultRegForm} from '../screens/auth/store/types';
import {api, apiPush} from './api';
import {ShiftOrdersState} from '../screens/shiftOrders/store/types';
import {ShiftState} from '../screens/shift/store/types';
import ShiftSlice from '../screens/shift/store/shift.slice';
import {ScannerState} from '../screens/scanner/store/types';
import ScannerSlice from '../screens/scanner/store/scanner.slice';
import shiftOrdersSlice from '../screens/shiftOrders/store/shift.orders.slice';

const persistConfig = {
    key: 'courier',
    storage,
    transforms: [
        encryptTransform({
            secretKey: process.env.REACT_APP_STORAGE_KEY,
        })
    ]
};
type StateReducer = {
    auth: AuthState;
    shiftOrders: ShiftOrdersState;
    shift: ShiftState;
    scanner: ScannerState
}

const rootReducer = combineReducers({
    auth: authSlice,
    shiftOrders: shiftOrdersSlice,
    shift: ShiftSlice,
    scanner: ScannerSlice,
    [apiPush.reducerPath]: apiPush.reducer,
    [api.reducerPath]: api.reducer,
});

const appReducer: Reducer<StateReducer | undefined, Action> = (state: any, action) => {
    if (action.type === logout.type) {
        const {auth: {user}} = state;
        state = {
            auth: {
                isAuth: false,
                user: user,
                form: defaultRegForm
            },
        };
    }
    return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }).concat(api.middleware as any, apiPush.middleware as any)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
