import React from 'react';
import {CardContainer, CardHeader, Col, Row} from '../../../utils';
import {Paragraph} from '../../../fields';
import {useNavigate} from 'react-router-dom';
import {ShiftOrder} from '../store/types';

export const Card = ({item}: { item: ShiftOrder }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/order/${item.OrderID}?ID_Obj=${item.ID_Obj}`);
    };

    return (
        <CardContainer onClick={handleClick}>
            <CardHeader>
                <Paragraph as={'span'} weight={'400'} colorType={'main'} fontSize={14}>Заказ
                    № {item.OrderNumber}</Paragraph>
                <Paragraph as={'span'} weight={'400'} colorType={'main'}
                           fontSize={14}>{item.OrderStatus}</Paragraph>
            </CardHeader>
            <Row>
                <Col span={3}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'} fontSize={14}>Объект</Paragraph>
                </Col>
                <Col span={9} direction={'flex-end'}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'}
                               fontSize={14}>{item.ObjName}</Paragraph>
                </Col>
            </Row>
            <Row>
                <Col span={3}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'} fontSize={14}>Сумма</Paragraph>
                </Col>
                <Col span={9} direction={'flex-end'}>
                    <Paragraph as={'span'} weight={'600'} colorType={'main'} fontSize={14}>{item.OrderSum}</Paragraph>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'}
                               fontSize={14}>Создан {item.DateCreated}</Paragraph>
                </Col>
                <Col span={6} direction={'flex-end'}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'}
                               fontSize={14}>Закрыт {item.DateClosed}</Paragraph>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'} fontSize={14}>Контакт
                        клиента</Paragraph>
                </Col>
                <Col span={6} direction={'flex-end'}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'}
                               fontSize={14}>{item.ClientPhone}</Paragraph>
                </Col>
            </Row>
            <Row>
                <Col span={3}>
                    <Paragraph as={'span'} weight={'400'} colorType={'main'} fontSize={14}>Примечание:</Paragraph>
                </Col>
                <Col span={9} direction={'flex-end'}>
                    <Paragraph
                        as={'span'}
                        weight={'400'}
                        textPosition={'right'}
                        colorType={'main'}
                        fontSize={14}>{item.OrderRem}</Paragraph>
                </Col>
            </Row>
        </CardContainer>
    );
};
