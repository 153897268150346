import styled from 'styled-components';

export type TextWeight =
    '300' |
    '400' |
    '600' |
    '500'

export const text = (type: TextWeight) => `
  font-family: Poppins, sans-serif;
  font-style: normal;
  @if (${type} == '300') {
    font-weight: 300;
  }
  @if (${type} == '400') {
    font-weight: 400;
  }
  @if (${type} == '600') {
    font-weight: 600;
  }
  @if (${type} == '500') {
    font-weight: 500;
  }
`;

export const Separator = styled.div.withConfig({
    shouldForwardProp: (prop) => !['space'].includes(prop)
})<{ space: number }>`
  display: inline-block;
  width: ${({space}) => space}px;
  height: ${({space}) => space}px;
  background-color: transparent;

`;

