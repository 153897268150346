import styled from 'styled-components';
import {Drawer} from 'antd';

export const Modal = styled(Drawer)`


  & .ant-drawer-body {
    padding: 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #eeeeee;
  padding: 20px;
  z-index: 2;
`;

export const ModalBody = styled.div`
  padding: 20px;
`;

export const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  padding: 0;
  position: absolute;
  top: 14px;
  right: 10px;


  &:hover {
    cursor: pointer;
  }
`;

export const VacancyList = styled.ul`
  padding: 0;
  margin: 0;
`;
