import React, {MouseEvent, useEffect, useMemo, useState} from 'react';
import {Box, Col, Container, Row, Separator} from '../../utils';
import {Button, Paragraph} from '../../fields';
import {Card} from './components';
import {useAppDispatch, useAppSelector} from '../../hooks/hook-store';
import {useGetWorkShiftQuery} from '../shiftOrders/store/shift.orders.api';
import {useGetUserInfoQuery} from '../account/store/account.api';
import {ShiftOrdersList} from '../shiftOrders/store/types';
import {Loader} from '../../components/loader';
import {ShiftFilter} from '../../components/shiftFilter';
import {useNavigate} from 'react-router-dom';
import {useCloseShiftMutation} from '../shift/store/shift.api';
import {toast} from 'react-toastify';
import {setBarcode, setShiftID} from '../scanner/store/scanner.slice';
import {Drawer} from 'antd';
import styled from 'styled-components';

export const Home = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {filter} = useAppSelector(state => state.shiftOrders);

    useGetUserInfoQuery();
    const {data, isLoading, refetch} = useGetWorkShiftQuery(undefined, {});
    const [closeShift] = useCloseShiftMutation();

    const {barcode, useShiftID} = useAppSelector(state => state.scanner);

    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (barcode && useShiftID && !open) {
            setOpen(true);
        }
    }, [barcode, useShiftID]);

    useEffect(() => {
        refetch();
    }, [filter]);

    const openShift = () => navigate('/shift');

    const onCloseShift = ({ID_Shift, qrContent}: { ID_Shift: string, qrContent: string }) => {
        closeShift({ID_Shift, qrContent})
            .unwrap()
            .catch(error => {
                toast.error(error.data.message);
            }).finally(() => {
            clearQrData();
        });
    };

    const clearQrData = () => {
        setOpen(false);
        dispatch(setBarcode(null));
        dispatch(setShiftID(null));
    };

    const closedShiftName = useMemo(() => data?.listWorkShift?.find((i: ShiftOrdersList) => String(i.ID) === useShiftID), [useShiftID]);

    return (
        <Container>
            {isLoading ? <Loader type={'large'}/> : null}
            <Box flex={true} flexDirection={'column'}>
                <Row>
                    <Col span={6}>
                        <Paragraph as={'span'} fontSize={16} weight={'400'} colorType={'main'}>Смены</Paragraph>
                    </Col>
                    <Col span={6}>
                        <Button
                            full={true}
                            height={40}
                            fontSize={16}
                            onClick={openShift}>Открыть смену</Button>
                    </Col>
                </Row>
                <ShiftFilter/>
                <Separator space={10}/>
                {data?.listWorkShift.map((item: ShiftOrdersList) => (
                    <Card key={item.ID} item={item}/>
                ))}
            </Box>
            <Drawer
                title=""
                placement={'bottom'}
                closable={false}
                height={200}
                onClose={() => {
                    clearQrData();
                }}
                open={open}
                key={'bottom'}
            >
                <Paragraph
                    as={'p'}
                    fontSize={16}
                    textPosition={'center'}
                    weight={'400'}
                    colorType={'main'}>Подтвердите закрытие смены {closedShiftName?.ObjName}</Paragraph>
                <Separator space={5}/>
                <Row>
                    <Col span={6}>
                        <Button
                            full={true}
                            fontSize={14}
                            height={45}
                            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                                clearQrData();
                            }}
                        >Нет</Button>
                    </Col>
                    <Col span={6}>
                        <ButtonConfirm
                            full={true}
                            fontSize={14}
                            height={45}
                            onClick={(event) => {
                                event.stopPropagation();
                                onCloseShift({ID_Shift: useShiftID as string, qrContent: barcode as string});
                                clearQrData();
                            }}
                        >
                            Да
                        </ButtonConfirm>
                    </Col>
                </Row>
            </Drawer>
        </Container>
    );
};

const ButtonConfirm = styled(Button)`
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: var(--white-color);
`;
