import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {persistor, store} from './store';
import {PersistGate} from 'redux-persist/integration/react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App/>
                {/*<InstallPWA/>*/}
                <ToastContainer position={'top-center'} autoClose={3000}/>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        const isUpdateConfirmed = window.confirm('Доступна новая версия. Хотите перезагрузить?');

        if (isUpdateConfirmed && registration.waiting) {
            registration.waiting.postMessage({type: 'SKIP_WAITING'});

            registration.waiting.addEventListener('statechange', (event: any) => {
                if (event.target?.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    },
});
