import React, {useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Box, Container, Separator} from '../../utils';
import {Paragraph, Switcher} from '../../fields';
import {Option} from '../../store/types';
import {useGetOrderDetailsQuery} from './store/order.api';
import {skipToken} from '@reduxjs/toolkit/query';
import {Delivery, Main, Products, Time} from './tabs';

const options: Option[] = [
    {label: 'Основная', value: 'main'},
    {label: 'Доставка', value: 'delivery'},
    {label: 'Товары', value: 'trade'},
    {label: 'Время', value: 'time'},
];

export const Order = () => {
    const {id} = useParams<{ id: string }>();
    const location = useLocation();
    const {ID_Obj} = Object.fromEntries(new URLSearchParams(location.search)) as { ID_Obj?: string };
    const [selected, setSelected] = useState<Option>(options[0]);

    const {data} = useGetOrderDetailsQuery(
        id && ID_Obj
            ? {
                ID_Obj,
                OrderID: id as string
            }
            : skipToken);


    const renderTab = useMemo(() => {
        if (!data) return null;
        const index = options.findIndex(opt => opt.value === selected.value);
        switch (index) {
            case 0:
                return <Main
                    OrderID={id as string}
                    item={data.orderInfo}
                    btnChangePaymentType={data.btnChangePaymentType}
                    customerPhone={data.customer.phone}
                />;
            case 1:
                return <Delivery item={data}/>;
            case 2:
                return <Products item={data}/>;
            default:
                return <Time item={data}/>;
        }
    }, [data, selected]);

    return (
        <Container>
            <Box flex={true} flexDirection={'column'}>
                <Paragraph
                    as={'p'}
                    colorType={'main'}
                    weight={'400'}
                    fontSize={16}
                >Заказ № {data?.orderInfo?.number}</Paragraph>
                <Separator space={10}/>
                <Switcher options={options} height={40} selected={selected} onSwitch={setSelected}/>
                <Separator space={10}/>
                {renderTab}
            </Box>
        </Container>
    );
};
