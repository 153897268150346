export const defaultRegForm = {
    name: '',
    phone: '',
    pwd: '',
    qrContent: '',
    confirmPassword: '',
    faceId: '',
};

export type AuthState = {
    isAuth: boolean;
    user: User;
    form: RegistrationForm
}

export type LoginForm = {
    login: string;
    pwd: string;
}

export type RegistrationTypes = {
    name: string;
    phone: string;
    pwd: string;
    qrContent: string;
    faceId?: string;
}

export interface RegistrationForm extends RegistrationTypes {
    confirmPassword: string;
}

export type User = {
    token: string;
    refreshToken: string | null,
    notificationToken: string;
    id_con: string;
    id: number;
    login: string;
    name: string;
    email: string | null;
    ID: number;
    Name: string;
    Login: string;
    Avatar: string | null,
    Phone: string;
    Email: string | null,
    ID_Type: number;
    ID_Iiko: string | null,
    ID_Telegram: string | null,
    TGCheckword: string | null,
    IsActive: number;
    IsSystem: number;
    IsOuter: number;
    WithoutLogin: number;
    MustChangePwd: number;
    Rem: string;
    TypeName: string;
}


export const defaultUser: User = {
    token: '',
    refreshToken: null,
    notificationToken: '',
    id_con: '',
    id: 0,
    login: '',
    name: '',
    email: null,
    ID: 0,
    Name: '',
    Login: '',
    Avatar: '',
    Phone: '',
    Email: '',
    ID_Type: 0,
    ID_Iiko: '',
    ID_Telegram: '',
    TGCheckword: '',
    IsActive: 0,
    IsSystem: 0,
    IsOuter: 0,
    WithoutLogin: 0,
    MustChangePwd: 0,
    Rem: '',
    TypeName: '',
};
