type Themes = {
    colors: { [key: string]: string }
}

export const themes: Themes = {
    colors: {
        main: '#4B5320',
        textColor: '#000000',
        secondText: '#6E7591',
        placeholder: '#c4c4c4',
        white: '#FFFFFF',
        disable: '#e3e3e3',
        borderColor: '#4B5320',
    }
};

// --app-height: 100%;
// --text-color: #000000;
// --second-text-color: #6E7591;
// --placeholder: #c4c4c4;
// --main-color: #4B5320;
// --white-color: #FFFFFF;
// --disable-color: #e3e3e3;
// --border-color: #4B5320;
// --error-color: #fc001f;
export const symbols = {
    rub: '₽'
};
