import React from 'react';
import {CardContainer, CardHeader, Col, Row} from '../../../utils';
import {Paragraph} from '../../../fields';
import {OrderInfo} from '../store/types';

const Item = ({text, subtext}: { text: string, subtext: string }) => {
    return (
        <Row>
            <Col span={8}>
                <Paragraph as={'span'} weight={'400'} colorType={'main'}
                           fontSize={14}>{subtext}</Paragraph>
            </Col>
            <Col span={4} direction={'flex-end'}>
                <Paragraph as={'span'} weight={'400'} colorType={'main'}
                           fontSize={14}>{text}</Paragraph>
            </Col>
        </Row>
    );
};

export const Time = ({item}: { item: OrderInfo }) => {

    const steps = item.table.body[0];
    return (
        <CardContainer>
            <CardHeader>
                <Item text={item.orderInfo.status} subtext={'Текущий статус:'}/>
            </CardHeader>
            {steps.DateCreated.display
                ? <Item text={steps.DateCreated.display} subtext={'Создан:'}/> : null}
            {steps.DateCompleteBefore.display
                ? <Item text={steps.DateCompleteBefore.display} subtext={'К сроку:'}/> : null}
            {steps.DateCookingStartTime.display
                ? <Item text={steps.DateCookingStartTime.display}
                        subtext={'Начало готовки:'}/> : null}
            {steps.DateCookingCompleted.display
                ? <Item text={steps.DateCookingCompleted.display} subtext={'Пригоровлен:'}/> : null}
            {steps.DateSended.display
                ? <Item text={steps.DateSended.display} subtext={'Отправлен:'}/> : null}
            {steps.DateDelivered.display
                ? <Item text={steps.DateDelivered.display} subtext={'Доставлен:'}/> : null}
            {steps.DateClosed.display
                ? <Item text={steps.DateClosed.display} subtext={'Закрыт:'}/> : null}
            {steps.CloseTg
                ? <Item text={steps.CloseTg} subtext={'Закрыт TG:'}/> : null}
            {steps.ResponceTG
                ? <Item text={steps.ResponceTG} subtext={'Доставлен TG:'}/> : null}


        </CardContainer>
    );
};
