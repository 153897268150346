import React, {useEffect, useState} from 'react';
import {Box, Col, Container, Row, Separator} from '../../utils';
import {Button, Checkbox, Input, Paragraph, Switcher} from '../../fields';
import dayjs from 'dayjs';
import {DateTag} from './component';
import {Option} from '../../store/types';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/hook-store';
import {chaneShiftForm} from './store/shift.slice';
import {defaultShiftForm, ShiftForm} from './store/types';
import {useOpenShiftMutation} from './store/shift.api';
import {toast} from 'react-toastify';
import {setBarcode} from '../scanner/store/scanner.slice';
import {Loader} from '../../components/loader';

const today = dayjs().format('DD MMM YYYY');

const ownBag: Option[] = [{label: 'Ресторана', value: 0}, {label: 'Своя', value: 1}];
const typeBag: Option[] = [
    {label: 'Пеший', value: 'Пеший'},
    {label: 'Вело', value: 'Вело'},
    {label: 'Авто', value: 'Авто'},
];

const getEmpty = (data: ShiftForm) => {
    return Object.entries(data)
        .filter(([, value]) => value === '')
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
};

export const Shift = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {form} = useAppSelector(state => state.shift);
    const {barcode} = useAppSelector(state => state.scanner);
    const [saveShift, {isLoading}] = useOpenShiftMutation();

    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (barcode) {
            handleChaneField('qrContent', barcode);
            dispatch(setBarcode(null));
        }
    }, []);

    const goToScanner = () => {
        navigate(`/scanner`, {state: {pathFrom: '/shift'}});
    };

    const handleChaneField = (key: string, value: string | number) => {
        dispatch(chaneShiftForm({...form, [key]: value}));
    };

    const handleSave = () => {
        const empty = getEmpty(form);

        if (Object.keys(empty).length > 0) {
            setErrors(empty);
        } else {
            saveShift(form)
                .unwrap()
                .then(res => {
                    if (res.status) {
                        navigate('/');
                    }
                })
                .catch(error => {
                    toast.error((error.data as any)?.message ?? 'Сервис не доступен, попробуйте позже.');
                })
                .finally(() => {
                    dispatch(chaneShiftForm(defaultShiftForm));
                });
        }
    };

    const handleCansel = () => {
        dispatch(chaneShiftForm(defaultShiftForm));
        navigate('/');
    };
    return (
        <Container>
            {isLoading ? <Loader type={'large'}/> : null}
            <Box padding={'10px 0'}>
                <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={16}>Открытие смены</Paragraph>
                <DateTag text={today}/>
                <Separator space={10}/>
                <Button
                    full={true}
                    fontSize={14}
                    height={45}
                    style={errors.qrContent === '' ? {borderColor: 'red'} : {}}
                    onClick={goToScanner}
                >Отсканируйте QR код</Button>
                <Separator space={10}/>
                <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Заполните параметры
                    смены:</Paragraph>
                <Separator space={20}/>
                <Row>
                    <Col span={4}>
                        <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Сумка</Paragraph>
                    </Col>
                    <Col span={8}>
                        <Switcher
                            options={ownBag}
                            height={45}
                            selected={ownBag.find(i => i.value === form.isOwnBag)}
                            onSwitch={(opt) => {
                                handleChaneField('isOwnBag', opt.value);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Номер сумки</Paragraph>
                    </Col>
                    <Col span={8}>
                        <Input
                            as={'input'}
                            isError={errors.bagNum === ''}
                            value={form.bagNum}
                            height={45}
                            onChange={event => handleChaneField('bagNum', event.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Тип</Paragraph>
                    </Col>
                    <Col span={8}>
                        <Switcher
                            options={typeBag}
                            height={45}
                            selected={typeBag.find(i => i.value === form.transportType)}
                            onSwitch={(opt) => {
                                handleChaneField('transportType', opt.value);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Номер
                            транспорта</Paragraph>
                    </Col>
                    <Col span={6}>
                        <Input
                            as={'input'}
                            isError={errors.transportNum === ''}
                            value={form.transportNum}
                            height={45}
                            onChange={event => handleChaneField('transportNum', event.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Paragraph as={'p'} colorType={'main'} weight={'400'} fontSize={14}>Мед книжка
                            есть</Paragraph>
                    </Col>
                    <Col span={6} direction={'flex-end'}>
                        <Checkbox
                            status={form.medicalNum === '1'}
                            onChange={status => {
                                handleChaneField('medicalNum', status ? '1' : '0');
                            }}
                        />
                    </Col>
                </Row>
                <Separator space={20}/>
                <Row>
                    <Col span={6}>
                        <Button full={true} height={45} onClick={handleCansel}>Отмена</Button>
                    </Col>
                    <Col span={6} direction={'flex-end'}>
                        <Button full={true} height={45} onClick={handleSave}>Сохранить</Button>
                    </Col>
                </Row>
            </Box>
        </Container>
    );
};

