import styled from 'styled-components';
import {text, TextWeight} from '../utils/styles';

type ParagraphProps = {
    as: any;
    weight: TextWeight;
    textPosition?: 'left' | 'center' | 'right';
    fontSize: number;
    colorType?: 'main' | 'second' | 'current-theme'
}

const props = ['weight', 'textPosition', 'fontSize', 'colorType'];

const getColor = (colorType?: 'main' | 'second' | 'current-theme') => {
    switch (colorType) {
        case 'main':
            return 'var(--text-color)';
        case 'second':
            return 'var(--second-text-color)';
        case 'current-theme':
            return 'var(--main-color)';
        default:
            return 'var(--white-color)';
    }
};

export const Paragraph = styled.p.attrs((props) => ({
    as: props.as || 'p'
})).withConfig({
    shouldForwardProp: (prop) => !props.includes(prop)
})<ParagraphProps>`
  font-size: ${({fontSize}) => fontSize}px;
  color: ${({colorType}) => getColor(colorType)};
  ${({weight}) => text(weight)};
  font-weight: ${({weight}) => weight};
  margin: 0;
  text-align: ${({textPosition}) => textPosition ? textPosition : 'left'};
`;
