import React from 'react';
import styled from 'styled-components';
import {Option} from '../store/types';

interface RadioButtonsProps {
    options: Option[];
    selected: string | number;
    direction?: 'row' | 'column';
    onSelected: (value: string) => void;
}

export const RadioButtons = (
    {
        options,
        selected,
        direction,
        onSelected
    }: RadioButtonsProps
) => {
    return (
        <RadioGroup direction={direction}>
            {options.map(opt => (
                <RadioLabel key={opt.value}>
                    <RadioButton
                        name={String(opt.value)}
                        value={opt.value}
                        checked={selected === opt.value}
                        onChange={() => onSelected(String(opt.value))}
                    />
                    {opt.label}
                </RadioLabel>
            ))}
        </RadioGroup>
    );
};

const RadioGroup = styled.div.withConfig({
    shouldForwardProp: prop => !['direction'].includes(prop)
})<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({direction}) => direction ? direction : 'row'};
  gap: 10px;
`;

// Стили для радио-кнопки
const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
`;

// Кастомная "точка" для радио-кнопки
const RadioButton = styled.input.attrs({type: 'radio'})`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--border-color);
  border-radius: 50%;
  margin-right: 8px;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--border-color);
  }

  &:focus {
    border-color: var(--border-color);
  }
`;
