import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Map} from '@pbe/react-yandex-maps';
import {Back, BackBtnWrap, Controls, Header, MapContainer, NavigatePanel, TopPanel} from './styled';
import {Button, Paragraph, Switcher} from '../../fields';
import {Icon} from '../../assets/icon';
import {themes} from '../../utils';
import {Option} from '../../store/types';
import {casesProductStatuses, statusProductOptions} from '../shiftOrders/ShiftOrders';
import {useGetTodayOrdersQuery} from './store/ordersMap.api';
import {Loader} from '../../components/loader';
import {DeliveryOrder} from './store/types';

export const MapCustom = () => {
    const mapRef = useRef<any>(null);
    const navigate = useNavigate();
    const [zoom, setZoom] = useState(10);
    const [ymaps, setYmaps] = useState<any>();
    const [selected, setSelected] = useState<Option>(statusProductOptions[0]);

    const {data, isLoading} = useGetTodayOrdersQuery({
        params: `FStatuses=${casesProductStatuses[selected.value]}`
    }, {refetchOnMountOrArgChange: true});

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }
    }, [zoom]);

    useEffect(() => {
        if (ymaps && mapRef.current && data) {

            for (const point of data as DeliveryOrder[]) {
                const address = [point.DeliveryCoord.Latitude, point.DeliveryCoord.Longitude];
                const object = [point.ObjCoord.Latitude, point.ObjCoord.Longitude];

                const carRoute = new ymaps.multiRouter.MultiRoute({
                        referencePoints: [
                            object, // Первая точка
                            address // Вторая точка
                        ],
                        params: {
                            // Параметры для первого маршрута
                            routingMode: 'auto', // Для автомобиля
                            avoid: [],
                            avoidTrafficJams: true,
                            results: 1
                        }
                    }
                );


                mapRef.current.geoObjects.add(carRoute);

                // Создаем мультимаршрут для велосипеда
                const bikeRoute = new ymaps.multiRouter.MultiRoute({
                        referencePoints: [
                            object, // Первая точка
                            address // Вторая точка
                        ],
                        params: {
                            routingMode: 'bicycle', // Для велосипеда
                        }
                    }
                );
                mapRef.current.geoObjects.add(bikeRoute);

                // Пример для пешеходного маршрута
                const pedestrianRoute = new ymaps.multiRouter.MultiRoute({
                        referencePoints: [
                            object, // Первая точка
                            address // Вторая точка
                        ],
                        params: {
                            routingMode: 'pedestrian', // Для пешеходов
                        }
                    }
                );

                // Добавляем пешеходный маршрут на карту
                mapRef.current.geoObjects.add(pedestrianRoute);

            }
        }
    }, [ymaps, data]);

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    mapRef.current.setCenter([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user\'s location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };

    return (
        <MapContainer>
            {isLoading ? <Loader type={'large'}/> : null}
            <Map
                defaultState={{zoom: 10, center: [54.1961, 37.6182] /* [55.755864, 37.617698] */}}
                width={window.innerWidth}
                height={window.innerHeight}
                instanceRef={mapRef}
                onLoad={ymaps => {
                    setYmaps(ymaps);
                }}
            >
                <TopPanel>
                    <Header>
                        <Paragraph as={'span'} colorType={'main'} fontSize={16} weight={'400'}>
                            Карта моих доставок
                        </Paragraph>
                    </Header>
                    <Switcher
                        height={43}
                        options={statusProductOptions}
                        selected={selected}
                        onSwitch={setSelected}
                    />
                </TopPanel>
                <Controls>
                    <Button full={false} onClick={handleZoomIn}>
                        <Icon type={'zoomIn'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>

                    <Button full={false} onClick={handleZoomOut}>
                        <Icon type={'zoomOut'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>
                    <Button full={false} onClick={getUserLocation}>
                        <Icon type={'location'} width={30} height={30} color={themes.colors.borderColor}/>
                    </Button>

                </Controls>
                <NavigatePanel>
                    <Back full={false} onClick={() => navigate('/')}>
                        <Icon type={'home'} width={20} height={20} color={themes.colors.borderColor}/>
                    </Back>
                    <Back full={false} onClick={() => navigate(-1)}>
                        <BackBtnWrap>
                            <Icon type={'back'} width={20} height={20} color={themes.colors.borderColor}/>
                        </BackBtnWrap>
                    </Back>
                </NavigatePanel>
            </Map>
        </MapContainer>
    );
};
