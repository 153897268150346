import React, {ChangeEvent, useCallback, useState} from 'react';
import {CardContainer, CardHeader, Col, Row, Separator} from '../../../utils';
import {Button, Input, Paragraph, RadioButtons} from '../../../fields';
import {OrderType} from '../store/types';
import {
    useChangePayMutation,
    useCheckedDeliveredMutation,
    useGetQrFeedbackMutation,
    useLazyGetProblemQuery,
    useSendProblemMutation
} from '../store/order.api';
import {Drawer} from 'antd';
import {toast} from 'react-toastify';
import {Option} from '../../../store/types';
import styled from 'styled-components';
import {Loader} from '../../../components/loader';

type MainProps = {
    OrderID: string;
    item: OrderType;
    btnChangePaymentType: boolean;
    customerPhone: string;
}

type DrawerType = 'problem' | 'change-pay' | 'feedback' | 'delivery' | null;
const isDisabledActions = [' CookingCompleted', 'OnWay'];

export const Main = (
    {
        item,
        OrderID,
        btnChangePaymentType,
        customerPhone
    }: MainProps) => {
    const [getProblem, {isLoading}] = useLazyGetProblemQuery();
    const [sendProblem] = useSendProblemMutation();
    const [getQrFeedback] = useGetQrFeedbackMutation();
    const [checkedDelivered] = useCheckedDeliveredMutation();
    const [changePay] = useChangePayMutation();

    const [open, setOpen] = useState(false);
    const [problemList, setProblemList] = useState<Option[]>([]);
    const [problem, setProblem] = useState<string>('');
    const [problemText, setProblemText] = useState<string>('');
    const [feedbackUrl, setFeedbackUrl] = useState<string | null>(null);
    const [drawerType, setDrawerType] = useState<DrawerType>(null);

    const getHeight = useCallback((type: DrawerType): number => {
        switch (type) {
            case 'problem':
                return 500;
            case 'feedback':
                return 500;
            case 'delivery':
                return 200;
            case 'change-pay':
                return 200;
            default:
                return 0;
        }
    }, [drawerType]);

    const handleGetProblem = () => {
        getProblem()
            .unwrap()
            .then((res) => {
                const opt = res.map(item => ({label: item, value: item}));
                setProblemList(opt);
                setDrawerType('problem');
                setOpen(true);
            })
            .catch(error => (
                toast.error(error.data.message)
            ));
    };

    const handleSendProblem = () => {
        const problemData = problem.toLowerCase() === 'другое' ? problemText : problem;
        sendProblem({OrderID, problem: problemData})
            .unwrap()
            .then(({status}) =>
                status
                    ? toast.info('Информация о проблеме успешно отправлена.')
                    : toast.error('Что-то пошло не так. Попробуйте позже.')
            )
            .catch(error => toast.error(error.data.message))
            .finally(() => clearDrawer());
    };

    const handleGetQrFeedback = () => {
        getQrFeedback({OrderID})
            .unwrap()
            .then(res => {
                setDrawerType('feedback');
                setOpen(true);
                setFeedbackUrl(res);
            })
            .catch(error => toast.error(error.data.message));
    };

    const handleDelivery = () => {
        setDrawerType('delivery');
        setOpen(true);
    };

    const handleCheckedDelivered = () => {
        checkedDelivered({OrderID})
            .unwrap()
            .then(({status}) => {
                status
                    ? toast.info('Заказ отмечен как доставленный')
                    : toast.info('Что-то пошло не так. Попробуйте позже.');
            })
            .catch(error => toast.error(error.data.message))
            .finally(clearDrawer);
    };

    const handlePay = () => {
        setDrawerType('change-pay');
        setOpen(true);
    };

    const handleChangePay = () => {
        changePay({OrderID})
            .unwrap()
            .then(({status}) => {
                status
                    ? toast.info('Способ оплаты изменен.')
                    : toast.info('Что-то пошло не так. Попробуйте позже.');
            })
            .catch(error => toast.error(error.data.message))
            .finally(clearDrawer);
    };

    const renderDrawerContent = () => {
        switch (drawerType) {
            case 'problem':
                return (
                    <>
                        <RadioButtons
                            direction={'column'}
                            options={problemList}
                            selected={problem}
                            onSelected={setProblem}/>
                        {problem.toLowerCase() === 'другое'
                            ?
                            <>
                                <Separator space={5}/>
                                <Input value={problemText}
                                       onChange={(event: ChangeEvent<HTMLInputElement>) => setProblemText(event.target.value)}/>
                            </>
                            : null}
                        <Separator space={5}/>
                        <DrawerButton type={'primary'} full={true} onClick={handleSendProblem}>
                            Отправить
                        </DrawerButton>
                    </>
                );
            case 'change-pay':
                return (
                    <>
                        <Paragraph
                            as={'p'}
                            weight={'400'}
                            colorType={'main'}
                            textPosition={'center'}
                            fontSize={22}>Изменить способ оплаты?</Paragraph>
                        <Separator space={10}/>
                        <Row>
                            <Col span={6}>
                                <DrawerButton type={'secondary'} full={true} onClick={() => {
                                    setDrawerType(null);
                                    setOpen(false);
                                }}>
                                    Нет
                                </DrawerButton>
                            </Col>
                            <Col span={6}>
                                <DrawerButton type={'primary'} full={true} onClick={handleChangePay}>
                                    Да
                                </DrawerButton>
                            </Col>
                        </Row>
                    </>
                );
            case 'delivery':
                return (
                    <>
                        <Paragraph
                            as={'p'}
                            weight={'400'}
                            colorType={'main'}
                            textPosition={'center'}
                            fontSize={22}>Доставлено?</Paragraph>
                        <Separator space={10}/>
                        <Row>
                            <Col span={6}>
                                <DrawerButton type={'secondary'} full={true} onClick={() => {
                                    setDrawerType(null);
                                    setOpen(false);
                                }}>
                                    Нет
                                </DrawerButton>
                            </Col>
                            <Col span={6}>
                                <DrawerButton type={'primary'} full={true} onClick={handleCheckedDelivered}>
                                    Да
                                </DrawerButton>
                            </Col>
                        </Row>
                    </>
                );
            case 'feedback':
                return (
                    <Image src={feedbackUrl as string} alt={'qr-code'}/>
                );
            default:
                return <></>;
        }
    };

    const clearDrawer = () => {
        setDrawerType(null);
        setOpen(false);
    };

    return (
        <>
            <CardContainer>
                <CardHeader>
                    <Paragraph
                        as={'p'}
                        colorType={'main'}
                        weight={'400'}
                        fontSize={14}
                    >Заказ № {item.number}</Paragraph>
                </CardHeader>
                <Row>
                    <Col span={3}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'}
                                   fontSize={14}>Статус:</Paragraph>
                    </Col>
                    <Col span={9} direction={'flex-end'}>
                        <Paragraph as={'span'} weight={'600'} colorType={'main'}
                                   fontSize={14}>{item.status}</Paragraph>
                    </Col>
                </Row>
                <Row>
                    <Col span={3}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'}
                                   fontSize={14}>Создан</Paragraph>
                    </Col>
                    <Col span={9} direction={'flex-end'}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'}
                                   fontSize={14}>{item.DateCreated}</Paragraph>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'}
                                   fontSize={14}>Закрыт </Paragraph>
                    </Col>
                    <Col span={6} direction={'flex-end'}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'}
                                   fontSize={14}>{item.DateClosed ?? '--'}</Paragraph>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'} fontSize={14}>Контакт
                            клиента</Paragraph>
                    </Col>
                    <Col span={6} direction={'flex-end'}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'}
                                   fontSize={14}>{customerPhone}</Paragraph>
                    </Col>
                </Row>
                <Row>
                    <Col span={3}>
                        <Paragraph as={'span'} weight={'400'} colorType={'main'}
                                   fontSize={14}>Примечание:</Paragraph>
                    </Col>
                    <Col span={9} direction={'flex-end'}>
                        <Paragraph
                            as={'span'}
                            weight={'400'}
                            textPosition={'right'}
                            colorType={'main'}
                            fontSize={14}>{item.rem}</Paragraph>
                    </Col>
                </Row>
            </CardContainer>
            <Row>
                <Col span={6}>
                    <Button
                        full={true}
                        disabled={!btnChangePaymentType}
                        fontSize={16}
                        onClick={handlePay}
                    >Смена оплат</Button>
                </Col>
                <Col span={6}>
                    <Button
                        full={true}
                        fontSize={16}
                        disabled={!isDisabledActions.includes(item.status_en)}
                        onClick={handleGetProblem}>
                        {isLoading
                            ? <Loader type={'small'}/>
                            : 'Проблема'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Button
                        full={true}
                        fontSize={16}
                        disabled={!isDisabledActions.includes(item.status_en)}
                        onClick={handleGetQrFeedback}>ОС гостя</Button>
                </Col>
                <Col span={6}>
                    <Button
                        full={true}
                        fontSize={16}
                        disabled={!isDisabledActions.includes(item.status_en)}
                        onClick={handleDelivery}>Доставил</Button>
                </Col>
            </Row>
            <Drawer
                title=""
                placement={'bottom'}
                closable={false}
                height={getHeight(drawerType)}
                onClose={() => setOpen(false)}
                open={open}
                key={'bottom'}
            >
                {renderDrawerContent()}
            </Drawer>

        </>
    );
};

const DrawerButton = styled(Button).withConfig({
    shouldForwardProp: prop => !['primary', 'secondary'].includes(prop)
})<{ type: 'primary' | 'secondary' }>`
  border-color: ${({type}) => type === 'primary' ? 'var(--main-color)' : 'var(--placeholder)'};
  background-color: ${({type}) => type === 'primary' ? 'var(--main-color)' : 'var(--placeholder)'};
  color: var(--white-color);
  height: 40px;
`;

const Image = styled.img`
  width: 100%;
`;
