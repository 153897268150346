import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button, Paragraph} from '../fields';
import {Col, Row} from '../utils';

export const InstallPWA = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
    const [showInstallButton, setShowInstallButton] = useState<boolean>(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: Event): void => {
            e.preventDefault();
            setDeferredPrompt(e);
            setShowInstallButton(true); // Показываем кнопку
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = async (): Promise<void> => {
        if (deferredPrompt && 'prompt' in deferredPrompt) {
            (deferredPrompt as any).prompt();
            const choiceResult = await (deferredPrompt as any).userChoice;
            if (choiceResult.outcome === 'accepted') {
                console.log('PWA установлено');
            }
            setDeferredPrompt(null);
            setShowInstallButton(false); // Убираем кнопку после установки
        }
    };

    return (
        <>
            {showInstallButton && (
                <ModalOverlay>
                    <ModalContent>
                        <ModalBox>
                            <Paragraph as={'p'} fontSize={16} weight={'600'} colorType={'main'}>
                                Для полноценной работы приложения вам надо установить его на рабочий стол.
                            </Paragraph>
                        </ModalBox>
                        <Row>
                            <Col span={6}>
                                <CanselButton
                                    full={true}
                                    fontSize={16}
                                    height={35}
                                    onClick={() => setShowInstallButton(false)}>
                                    Отказаться</CanselButton>
                            </Col>
                            <Col span={6}>
                                <InstallButton
                                    full={true}
                                    fontSize={16}
                                    height={35}
                                    onClick={handleInstallClick}>
                                    Установить</InstallButton>
                            </Col>
                        </Row>
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(196, 196, 196, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  width: 300px;
  padding: 10px;
  border-radius: 12px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
`;

const ModalBox = styled.div`
  padding: 10px 0 20px;
`;

const InstallButton = styled(Button)`
  background-color: var(--error-color);
  border: 1px solid darkred;
  color: #FFFFFF;
`;

const CanselButton = styled(Button)`
  background-color: var(--placeholder);
  border: 1px solid #333333;
  color: #333333;
`;
