import React, {useEffect, useId, useRef, useState} from 'react';
import {Icon} from '../../assets/icon';
import {TooltipContainer, TooltipContent, TooltipPosition, TooltipToggle} from './styles';
import {Paragraph} from '../../fields';
import {getCountLines, getTooltipPosition} from '../../helpers';

interface TooltipProps {
    text: string;
    activeTooltipId: string | null;
    setActiveTooltipId: (id: string | null) => void;
}

export const Tooltip = ({text, setActiveTooltipId, activeTooltipId}: TooltipProps) => {
    const id = useId();
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState<TooltipPosition>('left');
    const [top, setTop] = useState<number>(0);

    useEffect(() => {
        const element = document.getElementById(id);
        if (element) {
            const tooltipPosition = getTooltipPosition(element);
            setPosition(tooltipPosition);
        }

        const paragraph = document.getElementById(`${id}-paragraph`);
        if (paragraph) {
            const lineCount = getCountLines(paragraph);
            setTop(lineCount);
        }

    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
                setActiveTooltipId(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setActiveTooltipId]);

    return (
        <TooltipContainer id={id} ref={tooltipRef}>
            <TooltipToggle onClick={() => setActiveTooltipId(activeTooltipId === id ? null : id)}>
                <Icon width={15} height={15} type={'question'}/>
            </TooltipToggle>

            <TooltipContent
                id={id}
                visible={activeTooltipId === id}
                position={position}
                top={top}
            >
                <Paragraph
                    id={`${id}-paragraph`}
                    weight={'400'}
                    fontSize={16}
                    colorType={'main'}
                >
                    {text}
                </Paragraph>
            </TooltipContent>
        </TooltipContainer>
    );
};

