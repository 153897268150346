import styled from 'styled-components';
import {text} from '../utils';

type ButtonProps = {
    full: boolean;
    color?: 'main';
    fontSize?: number;
    height?: number
}

export const Button = styled.button.withConfig({
    shouldForwardProp: (prop) => !['full', 'color', 'fontSize', 'height'].includes(prop)
})<ButtonProps>`
  position: relative;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({full}) => full ? '100%' : 'auth'};
  height: ${({height}) => height ? height + 'px' : '33px'};
  color: ${({color}) => color ? 'var(--main-color)' : 'var(--text-color)'};
  font-size: ${({fontSize}) => fontSize ? fontSize + 'px' : '14px'};
  ${text('400')};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
