import styled from 'styled-components';

export const MainLayout = styled.div`
  padding: 10px;
  background: linear-gradient(to bottom, #ffffff 10%, rgba(112, 130, 56, 0.55) 60%, #ffffff 130%);
  width: 100vw;
  height: var(--app-height);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

