import {api} from '../../../store/api';
import {UserInfoAccount} from './types';

export const accountApi = api.injectEndpoints({
    endpoints: build => ({
        getUserInfo: build.query<UserInfoAccount, void>({
            query: () => ({
                url: '/user/info',
                method: 'GET'
            }),
            keepUnusedDataFor: 600000,
            transformResponse: (data: { status: boolean, UserInfo: UserInfoAccount }) => {
                return data.UserInfo;
            }
        })
    }), overrideExisting: true
});

export const {useGetUserInfoQuery} = accountApi;
