import styled from 'styled-components';
import {Container, Form} from '../../utils';
import {Input} from '../../fields';

export const AuthContainer = styled(Container)`
  height: 100%;
  overflow: auto;
  max-width: 475px;
  margin: 30px auto 0;
  justify-content: flex-start;
`;

export const FormLogin = styled(Form).withConfig({
    shouldForwardProp: (prop) => !['paddingTop'].includes(prop)
})<{ paddingTop: number }>`
  padding-top: ${({paddingTop}) => paddingTop}px;
`;

export const FormAuth = styled.div.withConfig({
    shouldForwardProp: (prop) => !['paddingTop'].includes(prop)
})<{ paddingTop: number }>`
  width: 100%;
  padding-top: ${({paddingTop}) => paddingTop}px;
`;

export const InputAuth = styled(Input)`
  display: flex;

  & input {
    font-size: 18px;
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
  width: 12px;
  height: 6px;
  border-left: 2px solid #28a745; /* Зеленая левая часть галочки */
  border-bottom: 2px solid #28a745; /* Зеленая нижняя часть галочки */
  transform: rotate(-45deg); /* Поворот для создания формы галочки */
  margin-right: 5px;
`;
