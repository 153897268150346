import React from 'react';
import styled from 'styled-components';
import {Option} from '../store/types';
import {text} from '../utils';

interface SwitcherProps {
    options: Option[];
    height: number;
    selected: Option | undefined;
    onSwitch: (opt: Option) => void;
}

export const Switcher = (
    {
        options,
        selected,
        onSwitch,
        height
    }: SwitcherProps
) => {
    return (
        <SwitcherContainer height={height}>
            {options.map(opt => <Item
                key={opt.value}
                isActive={opt.value === selected?.value}
                width={100 / options.length}
                onClick={() => onSwitch(opt)}
            >{opt.label}</Item>)}
        </SwitcherContainer>
    );
};

const SwitcherContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['height'].includes(prop)
})<{ height: number }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${({height}) => height}px;
  background-color: var(--white-color);
  padding: 2px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
`;

const Item = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop)
})<{ isActive: boolean; width: number; }>`
  border-radius: 10px;
  width: ${({width}) => width}%;
  background-color: ${({isActive}) => isActive ? 'var(--main-color)' : 'transparent'};
  color: ${({isActive}) => isActive ? 'var(--white-color)' : 'var(--text-color)'};
  ${text('400')};

  display: flex;
  align-items: center;
  justify-content: center;
`;
