import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ScannerState} from './types';

const initialState: ScannerState = {
    barcode: null,
    useShiftID: null
};

export const asyncUpdateBarcode = createAsyncThunk(
    'scanner/updateBarcode',
    async ({barcode, ID_Shift}: { barcode: string, ID_Shift?: string }, {dispatch}) => {
        dispatch(setBarcode(barcode));
        ID_Shift && dispatch(setShiftID(ID_Shift));
        return barcode; // Возвращаем значение для дальнейших действий
    }
);

const scannerSlice = createSlice({
    name: 'scanner',
    initialState,
    reducers: {
        setBarcode: (state, {payload}) => {
            state.barcode = payload;
        },
        setShiftID: (state, {payload}) => {
            state.useShiftID = payload;
        }
    }
});

export const {setBarcode, setShiftID} = scannerSlice.actions;

export default scannerSlice.reducer;
