import React from 'react';
import {OrderInfo} from '../store/types';
import {Box, Col, Row, Separator} from '../../../utils';
import {Paragraph} from '../../../fields';
import styled from 'styled-components';
import {symbols} from '../../../utils/themes';

const pizza_1 = require('../../../assets/images/products/pizza-1.png');
const pizza_2 = require('../../../assets/images/products/pizza-2.png');
const pizza_3 = require('../../../assets/images/products/pizza-3.png');
const pizza_4 = require('../../../assets/images/products/pizza-4.png');
const pizza_5 = require('../../../assets/images/products/pizza-5.png');
const pizza_6 = require('../../../assets/images/products/pizza-6.png');
const pizza_7 = require('../../../assets/images/products/pizza-7.png');

const defaultImages = [
    pizza_1,
    pizza_2,
    pizza_3,
    pizza_4,
    pizza_5,
    pizza_6,
    pizza_7
];

export const Products = ({item}: { item: OrderInfo }) => {
    const products = item.orderContent;

    return <ProductList>
        {products.map((item, idx) => (
            <Product key={idx}>
                <ImageWrap>
                    <Img src={defaultImages[idx]} alt={'product'}/>
                </ImageWrap>
                <Box padding={'0 5px 0'}>
                    <Paragraph as={'p'} fontSize={16} colorType={'main'}
                               weight={'400'}>{item.product.name}</Paragraph>
                    <Separator space={10}/>
                    <Row>
                        <Col span={6}>
                            Количество:
                        </Col>
                        <Col span={6} direction={'flex-end'}>
                            <Paragraph as={'span'} fontSize={16} colorType={'main'}
                                       weight={'400'}>{item.amount}</Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            Стоимость:
                        </Col>
                        <Col span={6} direction={'flex-end'}>
                            <Paragraph as={'span'} fontSize={16} colorType={'main'}
                                       weight={'400'}>{item.resultSum} {symbols.rub}</Paragraph>
                        </Col>
                    </Row>
                </Box>
            </Product>
        ))}
    </ProductList>;
};

const ProductList = styled.div`
  padding-top: 10px;
`;

const Product = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid var(--border-color);
  padding: 5px;
  background-color: var(--white-color);
  margin-bottom: 20px;
  border-radius: 12px;
`;

const ImageWrap = styled.div`
  width: 40%;
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;
